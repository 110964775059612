import { Spin } from "antd";
import React from "react";

import LoadingImage from "../../images/loader/classic-loader.svg";

const Loading = () => {
  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "transparent",
      }}
    >
      {/* <Spin tip="Loading" size="large"></Spin> */}
      <img src={LoadingImage} alt="loading" />
    </div>
  );
};

export default Loading;
