import React from "react";
import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "600px",
    height: "400px",
    // backgroundColor: "grey",
    zIndex: "1",
    padding: "0px",
    border: "none",
    //     -webkit-box-shadow: 10px 10px 26px 6px rgba(216,217,240,1);
    // -moz-box-shadow: 10px 10px 26px 6px rgba(216,217,240,1);
    boxShadow: " 10px 10px 95px -19px rgba(105,112,125,1)",
  },
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    // backgroundColor: "rgba(255, 255, 255, 0.75)",
    backgroundColor: "transparent",
    zIndex: "99999999999999999999999999999999999999",
  },
};

export default function Modal2({ setIsOpen, isOpen }) {
  let subtitle;
  //   const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
  }
  return (
    <div>
      {/* <button onClick={openModal}>Open Modal</button> */}
      <Modal
        isOpen={isOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
        // shouldFocusAfterRender={false}
        // ariaHideApp={false}
        preventScroll={true}
        centered={true}
      >
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            zIndex: "9999999999999999",
          }}
        >
          <div
            style={{
              width: "60%",
              height: "100%",
              backgroundColor: "#146cda",
            }}
          >
            modal left
          </div>
          <div style={{ width: "100%", height: "100%" }}>modal right</div>
        </div>
      </Modal>
    </div>
  );
}
