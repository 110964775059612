import React from "react";
import {
  ContactContainer,
  ContactContent,
  ContactContentWrapper,
  ContactDetails,
  ContactDivWrapper,
  ContactMainTitle,
  ContactTitle,
  ContactWrapper,
} from "./style";
import { TbLetterI } from "react-icons/tb";

export default function Contact() {
  return (
    <ContactContainer>
      <ContactMainTitle>Contact us</ContactMainTitle>
      <ContactWrapper>
        <ContactDivWrapper>
          <ContactContent>
            <ContactContentWrapper mt>
              <ContactTitle>Contact Details</ContactTitle>
              <ContactDetails>
                <a href="tel:+918453925376" style={{ color: "inherit" }}>
                  +91 8453925376
                </a>
                <a href="tel:+918453925376" style={{ color: "inherit" }}>
                  +91 9864038599 <TbLetterI />
                </a>
              </ContactDetails>
              <ContactDetails>
                <a
                  href="mailto:bipul.mobilefirst@gmail.com"
                  style={{ color: "inherit" }}
                >
                  classic.ghy@gmail.com
                </a>
              </ContactDetails>
            </ContactContentWrapper>
          </ContactContent>
          <ContactContent>
            <ContactContentWrapper top>
              <ContactTitle>Address</ContactTitle>
              <ContactDetails id="address">
                136, Maniram Dewan Rd Chandmari, Guwahati, Assam 781003
              </ContactDetails>
            </ContactContentWrapper>
          </ContactContent>
          <ContactContent>
            <ContactContentWrapper top>
              <ContactTitle>Shop Opening Hours</ContactTitle>
              <ContactDetails>Mon - Sun : 11:00 AM - 08:00 PM</ContactDetails>
            </ContactContentWrapper>
          </ContactContent>
        </ContactDivWrapper>
      </ContactWrapper>
    </ContactContainer>
  );
}
