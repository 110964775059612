import React from "react";
import "./style.css";
import axios from "axios";
import * as keys from "../../config/keys";
import { useState } from "react";
import { useEffect } from "react";
import { Card } from "antd";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const { Meta } = Card;
const FiveProduct = () => {
  const navigate = useNavigate();
  const [featureData, setFeatureData] = useState();
  const images =
    "https://img.global.news.samsung.com/in/wp-content/uploads/2022/04/SM-M536_Galaxy-M53-5G_Blue_FrontBack_RGB-min.png";
  const tv =
    "https://www.pngall.com/wp-content/uploads/5/Smart-Samsung-TV-PNG-Image.png";
  const laptop =
    "https://www.asus.com/media/Odin/Websites/global/ProductLine/20210913111446.png";
  const ac = "https://freepngimg.com/save/9191-ac-png-picture/638x272";
  const ref =
    "https://media.croma.com/image/upload/v1673259721/Croma%20Assets/Large%20Appliances/Refrigerator/Images/251547_0_obey6t.png";

  const getFeatureProduct = async () => {
    await axios
      .get(`${keys.PRODUCTION_URL}/auth/get/feature/product`)
      .then((res) => {
        if (res.status === 200) {
          setFeatureData(res.data);
        }
      })
      .catch((err) => {});
  };
  useEffect(() => {
    getFeatureProduct();
  }, []);

  const Redirect = (value) => {
    navigate(value);
  };

  return (
    <>
      {featureData && (
        <>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <span
              style={{
                fontSize: "22px",
                fontFamily: "monoto",
                fontWeight: "bold",
                textDecoration: "underline",
              }}
            >
              Featured Products
            </span>
          </div>
          <div class="card-group">
            <div class="large" data-aos="fade-right">
              <div className="singleImage">
                <img
                  src={`${keys.IMAGE_URL_PRODUCTION}/${featureData?.[0]?.images?.[0]}`}
                  style={{
                    width: "100%",
                    height: "60vh",
                    objectFit: "contain",
                  }}
                  alt="pimg"
                />
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <h3
                    style={{
                      textAlign: "center",
                      color: "grey",
                      fontWeight: "bold",
                    }}
                  >
                    {featureData?.[0]?.title}
                    {/* Side-by-Side Refrigerator with Inverter Linear Compressor,
              DoorCooling+™ */}
                  </h3>
                  <button
                    style={{
                      padding: "10px 20px",
                      border: "none",
                      borderRadius: "20px",
                      color: "white",
                      backgroundColor: "black",
                      fontWeight: "bold",
                    }}
                    onClick={() =>
                      Redirect(`/productDetails/${featureData?.[0]?._id}`)
                    }
                  >
                    Shop now
                  </button>
                </div>
              </div>
            </div>
            <div class="large ">
              <div className="fourCard">
                {featureData && featureData?.[1] && (
                  <div
                    class="card one"
                    onClick={() =>
                      Redirect(`/productDetails/${featureData?.[1]?._id}`)
                    }
                    data-aos="fade-up"
                  >
                    <img
                      src={`${keys.IMAGE_URL_PRODUCTION}/${featureData?.[1]?.images?.[0]}`}
                      style={{
                        width: "70%",
                        height: "40%",
                        objectFit: "contain",
                      }}
                      className="images"
                      alt="productimage"
                    />
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexWrap: "wrap",
                        width: "100%",
                      }}
                    >
                      <span className="product-title">
                        {featureData?.[1]?.title.slice(0, 30)}
                      </span>
                    </div>
                  </div>
                )}
                {featureData && featureData?.[2] && (
                  <div
                    class="card two"
                    onClick={() =>
                      Redirect(`/productDetails/${featureData?.[2]?._id}`)
                    }
                    data-aos="fade-down"
                  >
                    <img
                      src={`${keys.IMAGE_URL_PRODUCTION}/${featureData?.[2]?.images?.[0]}`}
                      style={{
                        width: "70%",
                        height: "40%",
                        objectFit: "contain",
                      }}
                      alt="productimage"
                      className="images"
                    />
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexWrap: "wrap",
                        width: "100%",
                      }}
                    >
                      <span className="product-title">
                        {featureData?.[2]?.title.slice(0, 30)}
                      </span>
                    </div>
                  </div>
                )}
                {featureData && featureData?.[3] && (
                  <div
                    class="card three"
                    onClick={() =>
                      Redirect(`/productDetails/${featureData?.[3]?._id}`)
                    }
                    data-aos="fade-up"
                  >
                    <img
                      src={`${keys.IMAGE_URL_PRODUCTION}/${featureData?.[3]?.images?.[0]}`}
                      style={{
                        width: "70%",
                        height: "40%",
                        objectFit: "contain",
                      }}
                      alt="productimage"
                      className="images"
                    />
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexWrap: "wrap",
                        width: "100%",
                      }}
                    >
                      <span className="product-title">
                        {featureData?.[3]?.title.slice(0, 30)}
                      </span>
                    </div>
                  </div>
                )}
                {featureData?.[4] && (
                  <div
                    class="card four"
                    onClick={() =>
                      Redirect(`/productDetails/${featureData?.[4]?._id}`)
                    }
                    data-aos="fade-down"
                  >
                    <img
                      src={`${keys.IMAGE_URL_PRODUCTION}/${featureData?.[4]?.images?.[0]}`}
                      style={{
                        width: "70%",
                        height: "40%",
                        objectFit: "contain",
                      }}
                      alt="productimage"
                      className="images"
                    />
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexWrap: "wrap",
                        width: "100%",
                      }}
                    >
                      {/* <Meta title={featureData?.[4]?.title.slice(0, 40)} /> */}
                      <span className="product-title">
                        {featureData?.[4]?.title.slice(0, 30)}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default FiveProduct;
