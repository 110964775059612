import styled from "styled-components";

export const ProductDetailCardContainer = styled.div`
  width: ${(props) => (props.w ? `20%` : `23%`)};
  background: red;
  display: flex;
  flex-direction: column;
  background-color: #fff !important;
  border: none;
  border-radius: 10px;
  padding: 10px;
  padding-top: 6px;
  height: auto;
  margin-bottom: 15px;
  position: relative;
  flex-wrap: wrap;
  column-gap: 20px;
  height: 410px;
  @media (max-width: 1148px) {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }
  @media (max-width: 1148px) and (min-width: 921px) {
    width: 32%;
  }
  @media (max-width: 921px) and (min-width: 768px) {
    width: ${(props) => (props.activeSideFilter === "open" ? `48%` : `32%`)};
    column-gap: 10px;
  }
  @media (max-width: 768px) and (min-width: 468px) {
    width: ${(props) => (props.activeSideFilter === "open" ? `48%` : `48%`)};
    /* width: 49%; */
    column-gap: 5px;
  }
  @media (max-width: 468px) and (min-width: 375px) {
    width: 48%;
    column-gap: 5px;
    // justify-content: center;
    height: auto;
  }
  @media (max-width: 376px) and (min-width: 0px) {
    width: 48%;
    column-gap: 5px;
    // justify-content: center;
    padding: 0px;
    // height: 380px;
  }
`;

export const ProductDetailLeft = styled.div`
  width: 100%;
  /* padding: 10px; */
  height: 230px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const ProductImageSection = styled.img`
  width: 100%;
  height: 80%;
  object-fit: contain;
  aspect-ratio: 2/3;

  transition: all 2s ease-in-out;

  @media (max-width: 468px) and (min-width: 375px) {
    width: 100%;
  }
  @media (max-width: 376px) and (min-width: 0px) {
    width: 80%;
  }
`;

export const ProductDetailRight = styled.div`
  width: 100%;
  // height: 200px;
  display: flex;
  /* align-items: start; */
  /* justify-content: space-between; */
  flex-direction: column;
`;
export const ProductDescriptionLeft = styled.div`
  width: 100%;
  height: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* padding: 20px 0px; */
  margin-top: 10px;
`;
export const ProductTitle = styled.span`
  font-size: 13px;
  /* font-family: "Roboto"; */
  text-align: center;
  color: black;
  font-weight: 500;
`;
export const RatingContainer = styled.div`
  margin-top: 15px;
  display: flex;
  align-items: center;
  flex-direction: row;
`;
export const TotalRating = styled.span`
  margin-left: 10px;
  margin-top: 5px;
  font-size: 15px;
  font-family: "Roboto";
  font-weight: 200;
`;
export const ItemDescriptionList = styled.ul`
  margin-top: 15px;
  margin-left: 15px;
  font-size: 15px;

  @media (max-width: 768px) and (min-width: 468px) {
    margin-left: 0px;
  }
`;
export const ItemListName = styled.li`
  font-family: Arial, Sans-Serif;
  margin-bottom: 8px;
`;
// export const ProductDetailCardContainer = styled.div``;

export const ProductDescriptionRight = styled.div`
  width: 100%;
  height: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media (max-width: 468px) and (min-width: 375px) {
    height: 100%;
  }
  @media (max-width: 376px) and (min-width: 0px) {
    height: 100%;
  }
`;
export const PriceSection = styled.h2`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  font-weight: 400;
  font-family: "Roboto";
  /* margin: 10px 10px 30px 10px; */
  color: #f00;
  margin-top: 30px;

  @media (max-width: 468px) and (min-width: 375px) {
    width: 100%;
    margin-top: 10px;
    font-size: 18px;
  }
  @media (max-width: 376px) and (min-width: 0px) {
    font-size: 15px;
    width: 100%;
    margin-top: 5px;
  }
`;
export const DeliverySection = styled.div`
  padding: 8px;
  border-radius: 4px;
  background-color: #f5f7fd;
  width: 100%;
  margin: 10px 10px 20px 10px;
`;
export const AddToCartButton = styled.div`
  width: 80%;
  /* background-color: blue; */
  margin: auto;
  cursor: pointer;

  @media (max-width: 768px) and (min-width: 551px) {
    width: 80%;
  }
  @media (max-width: 550px) and (min-width: 469px) {
    width: 100%;
  }
  @media (max-width: 468px) and (min-width: 377px) {
    width: 100%;
    // column-gap: 5px;
    // justify-content: center;
    margin-bottom: 5px;
  }
  @media (max-width: 376px) and (min-width: 0px) {
    width: 80%;
    column-gap: 5px;
    // justify-content: center;
    margin-bottom: 10px;
  }
`;
export const CartButton = styled.button`
  padding: 10px 30px;
  cursor: pointer;
  width: 100%;
  border: none;
  border-radius: 30px;
  /* background-color: #146cda; */
  /* background-color: #1800be; */
  background-color: white;
  color: black;
  border: 1px solid black;
  font-weight: 600;
  transition: all 0.5s ease-in;
  :hover {
    border: 1px solid white;
    /* background-color: #555; */
    /* background-color: #ddd; */
    /* background-color: #1800be; */
    background-color: black;
    color: white;
    /* filter: grayscale(50%); */
  }
  @media (max-width: 468px) and (min-width: 375px) {
    padding: 10px;
  }
  @media (max-width: 376px) and (min-width: 0px) {
    padding: 8px;
  }
`;
// export const ProductDetailCardContainer = styled.div``;
// export const ProductDetailCardContainer = styled.div``;
