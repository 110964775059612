import React from "react";
import { Modal } from "antd";
import { ModalInnerContainer, Title } from "./style";
import FormComponent from "../Form";
import toast from "react-hot-toast";
import { useState } from "react";
import { ModalDiv } from "../OrderSummary/style";

export default function ModalAccount(props) {
  const [seconds, setSeconds] = useState(30);
  const handleCancel = () => {
    if (window.confirm("You want to cancel your order!")) {
      setSeconds(30);
      toast.error("Order Cancelled!");
      props.setModal2Visible(false);
    }
  };

  // useEffect(() => {
  //   let interval = null;
  //   if (props.otps) {
  //     interval = setInterval(() => {
  //       setSeconds((seconds) => seconds - 1);
  //     }, 1000);
  //     if (seconds === 0) {
  //       clearInterval(interval);
  //     }
  //     return () => clearInterval(interval);
  //   }
  // }, [seconds]);
  return (
    <>
      <ModalDiv>
        <Modal
          visible={props.modal2Visible}
          onOk={() => handleCancel()}
          onCancel={() => handleCancel()}
          footer={null}
          style={{
            textAlign: "center",
          }}
          bodyStyle={{
            backgroundColor: "white",
          }}
          centered={true}
        >
          <ModalInnerContainer>
            <Title>OTP</Title>
            <FormComponent
              seconds={seconds}
              setSeconds={setSeconds}
              setUser={props.setUser}
              setModal2Visible={props.setModal2Visible}
            />
          </ModalInnerContainer>
        </Modal>
      </ModalDiv>
    </>
  );
}
