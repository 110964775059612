import React from "react";

// partner images
import Asus from "../../images/product-logo/AsusTek-black-logo.png";
import BlueStar from "../../images/product-logo/Blue_Star_Infotech-Logo.wine.png";
import Daikin from "../../images/product-logo/Daikin-Logo-1953.png";
import Dell from "../../images/product-logo/Dell_Logo.svg.png";
import Godrej from "../../images/product-logo/Godrej_&_Boyce-Logo.wine.svg";
import Hp from "../../images/product-logo/HP_logo_630x630.png";
import IFB from "../../images/product-logo/IFB.png";
import Iphone from "../../images/product-logo/IPhone_Logo_2016.svg.png";
import Lenovo from "../../images/product-logo/Lenovo-Logo-1.png";
import Lg from "../../images/product-logo/lg-logo.png";
import Oppo from "../../images/product-logo/oppo-green-logo-transparent-0.png";
import Realme from "../../images/product-logo/Realme-Logo-HD.png";
import Redmi from "../../images/product-logo/redmi.png";
import Samsung from "../../images/product-logo/samsung_logo_PNG8.png";
import Sony from "../../images/product-logo/Sony-Logo-1957.png";
import Vivo from "../../images/product-logo/Vivo_logo_2019.svg.png";
import Voltas from "../../images/product-logo/Voltas-AC-Logo-PNG.png";
import Xiaomi from "../../images/product-logo/Xiaomi-Logo-2019.png";
import Apple from "../../images/product-logo/Apple-Logo.png";
import Marquee from "react-fast-marquee";

import "./style.css";

const ImageMarquee = () => {
  const images = [
    Asus,
    BlueStar,
    Daikin,
    Dell,
    Godrej,
    Hp,
    IFB,
    Apple,
    Lenovo,
    Lg,
    Oppo,
    Realme,
    Redmi,
    Samsung,
    Sony,
    Vivo,
    Voltas,
    Xiaomi,
  ];
  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          paddingLeft: "40px",
          paddingRight: "40px",
          flexDirection: "column",
        }}
        className=""
      >
        <div>
          <h3>Our Partners</h3>
        </div>
        <Marquee
          style={{
            // width: "100%",
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "center",
            maxHeight: "100px",
          }}
          gradientColor={[248, 251, 253]}
          gradient={true}
          autoFill={true}
          speed={100}
          loop={0}
          delay={0}
          //   play={false}
        >
          {images.map((image) => (
            <img
              src={image}
              style={{
                width: "200px",
                height: "auto",
                maxHeight: "80px",
                marginLeft: "40px",
              }}
              alt="partner image"
              className="marquee-style"
            />
          ))}
        </Marquee>
      </div>
    </>
  );
};

export default ImageMarquee;
