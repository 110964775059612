function formatIndianNumber(number) {
  if (typeof number === "number") {
    // Use toLocaleString() with 'en-IN' locale for Indian number formatting
    return number.toLocaleString("en-IN");
  } else {
    const convertNumberToInt = parseInt(number);
    return convertNumberToInt.toLocaleString("en-IN");
  }
}
export default formatIndianNumber;
