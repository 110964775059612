import React, { useState } from "react";
import Slider from "../components/Carousel";
import Category from "../components/Categories";
import Contact from "../components/Contact";
import Map from "../components/map";
import Navbar from "./Navbar";
import Product from "./Product";
import WhatsApp from "../images/wp.png";
import { BackTop } from "antd";
import { AiOutlineArrowUp } from "react-icons/ai";
import FooterMain from "../components/Footer";
import { useEffect } from "react";
import { useStateContext } from "../contextApi/StateContext";
import ImageMarquee from "../components/MaruqeeImage";
import Loading from "../components/Loading";

const style = {
  height: 40,
  width: 40,
  lineHeight: "40px",
  borderRadius: 4,
  backgroundColor: "black",
  color: "#fff",
  textAlign: "center",
  fontSize: 14,
  right: 25,
  bottom: 7,
};
export default function Home() {
  const { getAllProduct, getAllCart, cartFromD } = useStateContext();

  useEffect(() => {
    getAllProduct();
    getAllCart();
  }, []);

  return (
    <>
      <div
        style={{
          width: "100%",
        }}
      >
        <Navbar />
        <Slider />
        <Category />
        <Product />

        <ImageMarquee />
        <Contact />
        <Map />
        <FooterMain />
        <a href="https://wa.me/919864038599" target="_blank" rel={"noreferrer"}>
          <span style={{ position: "fixed", bottom: "50px", right: "20px" }}>
            <img
              src={WhatsApp}
              style={{ width: "50px", height: "50px" }}
              alt="whatsaap"
            />
          </span>
        </a>
        <BackTop visibilityHeight={"400"} style={style}>
          <AiOutlineArrowUp style={{ color: "white" }} />
        </BackTop>
      </div>
    </>
  );
}
