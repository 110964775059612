import React from "react";
import { Link } from "react-router-dom";

import {
  CategoryContainer,
  CategoryItemContent,
  CategoryItemDiv,
  CategoryItemImage,
  CategoryItemTitle,
  CategoryItemWrapper,
  CategoryTitle,
  CategoryWrapper,
} from "./style";
import Freeze from "../../images/refrigerator_PNG9062.png";
import Laptop from "../../images/laptop (3).png";
import Mobile from "../../images/categoryImage/phone.png";
import WM from "../../images/categoryImage/washingMachine.png";
import HandK from "../../images/categoryImage/homeAppliance.png";
import AC from "../../images/categoryImage/ac.png";
import TV from "../../images/categoryImage/tv.png";

export default function Category() {
  return (
    <CategoryContainer>
      <CategoryWrapper>
        <CategoryTitle>Shop Deals</CategoryTitle>
        <CategoryItemWrapper>
          <Link to={"/product/kitchen&otherappliances"}>
            {/* <Link to={"/product/home&kitchenappliances"}> */}
            <CategoryItemDiv
              data-aos="zoom-in"
              data-aos-anchor-placement="top-bottom"
            >
              <CategoryItemContent>
                <CategoryItemImage src={HandK} />
              </CategoryItemContent>
              <CategoryItemTitle>Kitchen & Other appliance</CategoryItemTitle>
            </CategoryItemDiv>
          </Link>

          <Link to={"/product/washing_machine"}>
            <CategoryItemDiv
              data-aos="zoom-in"
              data-aos-anchor-placement="top-bottom"
            >
              <CategoryItemContent>
                <CategoryItemImage src={WM} />
              </CategoryItemContent>
              <CategoryItemTitle>Washing Machine</CategoryItemTitle>
            </CategoryItemDiv>
          </Link>
          <Link to={"/product/refrigerator"}>
            <CategoryItemDiv
              data-aos="zoom-in"
              data-aos-anchor-placement="top-bottom"
            >
              <CategoryItemContent>
                <CategoryItemImage src={Freeze} style={{ width: "60px" }} />
              </CategoryItemContent>
              <CategoryItemTitle>Refrigerator</CategoryItemTitle>
            </CategoryItemDiv>
          </Link>
          <Link to={"/product/mobile_phone"}>
            <CategoryItemDiv
              data-aos="zoom-in"
              data-aos-anchor-placement="top-bottom"
            >
              <CategoryItemContent>
                <CategoryItemImage src={Mobile} />
              </CategoryItemContent>
              <CategoryItemTitle>Mobile phones</CategoryItemTitle>
            </CategoryItemDiv>
          </Link>
          <Link to={"/product/tv"}>
            <CategoryItemDiv
              data-aos="zoom-in"
              data-aos-anchor-placement="top-bottom"
            >
              <CategoryItemContent>
                <CategoryItemImage src={TV} />
              </CategoryItemContent>
              <CategoryItemTitle> LED TV & Audio</CategoryItemTitle>
            </CategoryItemDiv>
          </Link>
          <Link to={"/product/ac"}>
            <CategoryItemDiv
              data-aos="zoom-in"
              data-aos-anchor-placement="top-bottom"
            >
              <CategoryItemContent>
                <CategoryItemImage src={AC} />
              </CategoryItemContent>
              <CategoryItemTitle>AC</CategoryItemTitle>
            </CategoryItemDiv>
          </Link>
          <Link to={"/product/laptop"}>
            <CategoryItemDiv
              data-aos="zoom-in"
              data-aos-anchor-placement="top-bottom"
            >
              <CategoryItemContent>
                <CategoryItemImage src={Laptop} />
              </CategoryItemContent>
              <CategoryItemTitle>Laptop</CategoryItemTitle>
            </CategoryItemDiv>
          </Link>
        </CategoryItemWrapper>
      </CategoryWrapper>
    </CategoryContainer>
  );
}
