import React, { useState } from "react";
import { Card, Row, Col } from "antd";

import "./style.css";
import axios from "axios";
import * as keys from "../../config/keys";
import { Link } from "react-router-dom";

const { Meta } = Card;

const SmallProductCard = () => {
  const [data, setData] = useState([]);

  const getData = async () => {
    axios
      .get(`${keys.PRODUCTION_URL}/product/get/latest/product`)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {});
  };

  return (
    <>
      {data?.length > 0 ? (
        <Card
          title={
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Meta title="Latest Product" />
            </div>
          }
        >
          <Row gutter={[16, 16]}>
            {data?.map((item) => (
              <Col xs={12} sm={12} md={6}>
                <Card
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    padding: "10px",
                  }}
                  //   hoverable
                  className="card-main"
                  cover={
                    <img
                      alt="Product 1"
                      src={`${keys.IMAGE_URL_PRODUCTION}/${item?.images[0]}`}
                      style={{
                        width: "100px",
                        height: "100px",
                        objectFit: "contain",
                      }}
                    />
                  }
                >
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <span>{item?.title.slice(0, 30)}</span>
                  </div>
                  <Link
                    to={{
                      pathname: `/productDetails/${item?._id}`,
                    }}
                    style={{ padding: "0px" }}
                    className="metaa"
                  >
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "20px",
                        padding: "0px",
                      }}
                    >
                      <button
                        className="hover-button"
                        style={{
                          width: "80%",
                          padding: "8px",
                          border: "none",
                          backgroundColor: "black",
                          color: "white",
                          fontWeight: "bold",
                        }}
                      >
                        View
                      </button>
                    </div>
                  </Link>
                </Card>
              </Col>
            ))}
          </Row>
        </Card>
      ) : (
        ""
      )}
    </>
  );
};

export default SmallProductCard;
