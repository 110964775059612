import React, { createContext, useContext } from "react";
import { useState } from "react";
import { toast } from "react-hot-toast";
import pincodeData from "../pin_code/pincode_ghy.json";
import axios from "axios";
import * as keys from "../config/keys";
import { useRef } from "react";

const Context = createContext();
const getLocalData = (client) => {
  // axios
  //   .get(`${keys.PRODUCTION_URL}/product/get/user/cart/${client?._id}`)
  //   .then((res) => {
  //     console.log(
  //       "res inside state context",
  //       res.data.map((ii) => ii.products.map((i) => i))
  //     );
  //     const data = res?.data?.map((data) =>
  //       data?.products?.map((product) => product)
  //     );
  //
  //     return data;
  //   })
  //   .catch((err) => {
  //
  //   });
  const lists = localStorage.getItem("cart");
  if (lists) {
    return JSON.parse(lists);
  } else {
    return [];
  }
};
const getLocalDataPrice = () => {
  const lists = localStorage.getItem("total_price");
  if (lists) {
    return lists;
  } else {
    return [];
  }
};
const getUserWishlist = () => {
  const wishlist = localStorage.getItem("wishlist");
  if (wishlist) {
    return JSON.parse(wishlist);
  } else {
    return [];
  }
};
const getUserBuyList = () => {
  const buy = localStorage.getItem("buy");
  if (buy) {
    return JSON.parse(buy);
  } else {
    return [];
  }
};
const getUser = () => {
  const client = localStorage.getItem("client");
  if (client) {
    return JSON.parse(client);
  } else {
    return [];
  }
};

export const StateContext = ({ children }) => {
  const htmlRef = useRef(null);

  const [cart, setCart] = useState(0);
  const [qty, setQty] = useState(1);
  const [brand, setBrand] = useState([]);
  const [productCategory, setProductCategory] = useState([]);
  const [brandValue, setBrandValue] = useState("");
  const [categoryValue, setCategoryValue] = useState("");
  const [apiData, setApiData] = useState(null);
  const [filterData, setFilterData] = useState(null);
  const [user, setUser] = useState([]);
  const [buy, setBuy] = useState(getUserBuyList());
  const [wishlist, setWishlist] = useState();
  const [client, setClient] = useState(getUser());
  // const [billingDetails, setBillingDetails] = useState({
  const [cartItems, setCartItems] = useState(getLocalData());
  const [formError, setFormError] = useState("");
  const [pincode, setPincode] = useState(pincodeData?.code);
  const [modal2Visible, setModal2Visible] = useState(false);
  const [redirect, setRedirect] = useState("");
  const [wishlistData, setWishlistData] = useState([]);
  const [otps, setOtps] = useState();
  const [orderAddress, setOrderAddress] = useState([]);
  const [paymentType, setPaymentType] = useState("cash");
  const [filValue, setFilvalue] = useState("");
  const [productByCategory, setProductByCategory] = useState("");
  const [allProduct, setAllProduct] = useState([]);
  const [cartFromD, setCartItemsFromDB] = useState([]);
  const [filterByBrand, setFilterByBrand] = useState("");
  const [checkOutLoading, setCheckOutLoading] = useState(false);

  //   city: "guwahati",
  //   state: "Assam",
  // });
  const [totalPrice, setTotal] = useState(
    cartItems?.reduce((acc, obj) => acc + obj.discount, 0)
  );

  const [releventFilter, setReleventFilter] = useState("");
  const [productData, setProductData] = useState(null);
  let foundProduct;

  const addItemToCartNew = async (
    product,
    quantity,
    removefromWishlist,
    selectedColor,
    selectedvariant
  ) => {
    const getAllCart = await axios
      .get(`${keys.PRODUCTION_URL}/cart/all/${client._id}`)
      .then((res) => {
        setCartItems(res.data);
      })
      .catch((error) => {});
  };

  const addItemToCart = async (
    product,
    quantity,
    removefromWishlist,
    selectedColor,
    selectedvariant
  ) => {
    const checkProductInCart = cartItems?.find(
      (item) => item._id === product._id
    );
    //

    if (checkProductInCart) {
      if (checkProductInCart.category === "mobile_phone") {
        if (
          Number(checkProductInCart.selectedvariant.discount_price) ===
            Number(selectedvariant.discount_price) &&
          checkProductInCart.selectedColor === selectedColor
        ) {
        } else {
          let uniqueId =
            Math.floor(Math.random() * 999999999999) + client._id.slice(8);
          const productPrice = Number(selectedvariant.discount_price);
          const products = {
            ...product,
            selectedColor: selectedColor,
            selectedvariant,
            uniqueId: uniqueId,
            productPrice: productPrice,
          };
          const gg = await axios
            .post(`${keys.PRODUCTION_URL}/cart/add/${client._id}`, products)
            .then((res) => {
              if (res.status === 200) {
                localStorage.setItem(
                  "cart",
                  JSON.stringify([
                    ...cartItems,
                    {
                      ...product,
                      selectedColor,
                      selectedvariant,
                      uniqueId,
                      productPrice,
                    },
                  ])
                );

                setCartItems([
                  ...cartItems,
                  {
                    ...product,
                    selectedColor,
                    selectedvariant,
                    uniqueId,
                    productPrice,
                  },
                ]);
              }
            })
            .catch((error) => {
              toast.error(error);
            });

          // localStorage.setItem(
          //   "cart",
          //   JSON.stringify([
          //     ...cartItems,
          //     {
          //       ...product,
          //       selectedColor,
          //       selectedvariant,
          //       mobileVariantId,
          //       productPrice,
          //     },
          //   ])
          // );

          // setCartItems([
          //   ...cartItems,
          //   {
          //     ...product,
          //     selectedColor,
          //     selectedvariant,
          //     mobileVariantId,
          //     productPrice,
          //   },
          // ]);
        }
      }
    } else {
      if (product.category === "mobile_phone") {
        const productPrice = Number(selectedvariant.discount_price);

        let uniqueId =
          Math.floor(Math.random() * 999999999999) + client._id.slice(8);
        const products = {
          ...product,
          selectedColor: selectedColor,
          selectedvariant,
          uniqueId: uniqueId,
          productPrice: productPrice,
        };
        const fg = await axios
          .post(`${keys.PRODUCTION_URL}/cart/add/${client._id}`, products)
          .then((res) => {
            if (res.status === 200) {
              localStorage.setItem(
                "cart",
                JSON.stringify([
                  ...cartItems,
                  {
                    ...product,
                    selectedColor,
                    selectedvariant,
                    uniqueId,
                    productPrice,
                  },
                ])
              );

              setCartItems([
                ...cartItems,
                {
                  ...product,
                  selectedColor,
                  selectedvariant,
                  uniqueId,
                  productPrice,
                },
              ]);
            }
          })
          .catch((error) => {
            toast.error(error);
          });
        // localStorage.setItem(
        //   "cart",
        //   JSON.stringify([
        //     ...cartItems,
        //     {
        //       ...product,
        //       selectedColor,
        //       selectedvariant,
        //       mobileVariantId,
        //       productPrice,
        //     },
        //   ])
        // );

        // setCartItems([
        //   ...cartItems,
        //   {
        //     ...product,
        //     selectedColor,
        //     selectedvariant,
        //     mobileVariantId,
        //     productPrice,
        //   },
        // ]);
      } else {
        const productPrice = Number(product.discount);
        let uniqueId =
          Math.floor(Math.random() * 999999999999) + client._id.slice(8);

        const products = {
          ...product,
          productPrice: productPrice,
          uniqueId: uniqueId,
        };
        const ffff = await axios
          .post(`${keys.PRODUCTION_URL}/cart/add/${client._id}`, products)
          .then((res) => {
            if (res.status === 200) {
              localStorage.setItem(
                "cart",
                JSON.stringify([
                  ...cartItems,
                  { ...product, productPrice, uniqueId },
                ])
              );

              setCartItems([
                ...cartItems,
                { ...product, productPrice, uniqueId },
              ]);
            }
          })
          .catch((error) => {
            toast.error(error);
          });

        // localStorage.setItem(
        //   "cart",
        //   JSON.stringify([...cartItems, { ...product, productPrice }])
        // );

        // setCartItems([...cartItems, { ...product, productPrice }]);
      }
      toast.success("Added to the cart.");
    }
    setTotal(
      (prevTotalPrice) => prevTotalPrice + product.productPrice * quantity
    );

    //
    // if (product.category === "mobile_phone") {
    //   setTotal(
    //     (prevTotalPrice) =>
    //       prevTotalPrice +
    //       Number(product) * quantity
    //   );
    // } else {
    // }

    // if (checkProductInCart) {
    //   const updatedCartItems = cartItems.map((cartProduct) => {
    //     if (cartProduct._id === product._id)
    //       return {
    //         ...cartProduct,
    //         quantity: cartProduct.quantity + quantity,
    //       };
    //     return cartProduct;
    //   });
    //   localStorage.setItem("cart", JSON.stringify(updatedCartItems));
    //   setCartItems(updatedCartItems);
    // } else {
    //   localStorage.setItem(
    //     "cart",
    //     JSON.stringify([
    //       ...cartItems,
    //       { ...product, selectedColor, selectedvariant },
    //     ])
    //   );

    //   setCartItems([
    //     ...cartItems,
    //     { ...product, selectedColor, selectedvariant },
    //   ]);
    // }
    if (removefromWishlist === 5) {
      addToWishlist(product);
    }
  };

  // remove itemns
  const removeItem = (productID, price, category, uniqueId) => {
    if (category === "mobile_phone") {
      foundProduct = cartItems.find((item) => item.uniqueId === uniqueId);
      const newCartItems = cartItems.filter(
        (item) => item.uniqueId !== uniqueId
      );
      setTotal(
        (prevTotalPrice) =>
          prevTotalPrice - foundProduct?.productPrice * foundProduct?.quantity
      );
      const deleteFromDB = async () => {
        await axios
          .delete(`${keys.PRODUCTION_URL}/cart/remove/product/${uniqueId}`)
          .then((res) => {
            toast.success("Product removed!");
          })
          .catch((err) => {});
      };
      deleteFromDB();

      localStorage.setItem("cart", JSON.stringify(newCartItems));

      setCartItems(newCartItems);
    } else {
      foundProduct = cartItems.find((item) => item._id === productID);
      const newCartItems = cartItems.filter((item) => item._id !== productID);
      setTotal(
        (prevTotalPrice) =>
          prevTotalPrice - foundProduct?.productPrice * foundProduct.quantity
      );

      const deleteFromDB = async () => {
        await axios
          .delete(`${keys.PRODUCTION_URL}/cart/remove/product/${uniqueId}`)
          .then((res) => {
            toast.success("Product removed!");
          })
          .catch((err) => {});
      };
      deleteFromDB();

      localStorage.setItem("cart", JSON.stringify(newCartItems));

      setCartItems(newCartItems);
    }
  };

  //
  const Incqty = (product, uniqueId) => {
    if (product.category === "mobile_phone") {
      const updatedCartItems = cartItems.map((cartProduct) => {
        if (cartProduct.uniqueId === uniqueId)
          return {
            ...cartProduct,
            quantity: cartProduct.quantity + 1,
          };
        return cartProduct;
      });
      const incTT = async () => {
        await axios
          .get(`${keys.PRODUCTION_URL}/cart/inc/${uniqueId}/${client._id}`)
          .then((res) => {})
          .catch((err) => {});
      };
      incTT();
      setTotal((prevTotalPrice) => prevTotalPrice + product?.productPrice);

      localStorage.setItem("cart", JSON.stringify(updatedCartItems));

      setCartItems(updatedCartItems);
    } else {
      const updatedCartItems = cartItems.map((cartProduct) => {
        if (cartProduct.uniqueId === product.uniqueId)
          return {
            ...cartProduct,
            quantity: cartProduct.quantity + 1,
          };
        return cartProduct;
      });
      // if (product.category === "mobile_phone") {
      //   setTotal(
      //     (prevTotalPrice) =>
      //       prevTotalPrice + Number(product?.selectedvariant?.discount_price)
      //   );
      // } else {
      const incTT = async () => {
        await axios
          .get(`${keys.PRODUCTION_URL}/cart/inc/${uniqueId}/${client._id}`)
          .then((res) => {})
          .catch((err) => {});
      };
      incTT();
      setTotal((prevTotalPrice) => prevTotalPrice + product.productPrice);
      // }
      localStorage.setItem("cart", JSON.stringify(updatedCartItems));

      setCartItems(updatedCartItems);
    }
  };

  // product decrement
  const DescQty = async (product, uniqueId) => {
    if (product.category === "mobile_phone") {
      if (product.quantity > 1) {
        const updatedCartItems = cartItems.map((cartProduct) => {
          if (cartProduct.uniqueId === uniqueId)
            return {
              ...cartProduct,
              quantity: cartProduct.quantity - 1,
            };

          return cartProduct;
        });
        const decTT = async () => {
          await axios
            .get(`${keys.PRODUCTION_URL}/cart/dec/${uniqueId}/${client._id}`)
            .then((res) => {})
            .catch((err) => {});
        };
        decTT();
        setTotal((prevTotalPrice) => prevTotalPrice - product.productPrice);
        localStorage.setItem("cart", JSON.stringify(updatedCartItems));
        setCartItems(updatedCartItems);
      }
      return 1;
    } else {
      if (product.quantity > 1) {
        const updatedCartItems = cartItems.map((cartProduct) => {
          if (cartProduct.uniqueId === product.uniqueId)
            return {
              ...cartProduct,
              quantity: cartProduct.quantity - 1,
            };
          return cartProduct;
        });
        const decTT = async () => {
          await axios
            .get(`${keys.PRODUCTION_URL}/cart/inc/${uniqueId}/${client._id}`)
            .then((res) => {})
            .catch((err) => {});
        };
        decTT();
        setTotal((prevTotalPrice) => prevTotalPrice - product.productPrice);
        localStorage.setItem("cart", JSON.stringify(updatedCartItems));

        setCartItems(updatedCartItems);
      }
      return 1;
    }
  };

  // add to wishlistr

  const addToWishlist = async (product) => {
    if (client?._id) {
      // foundProduct = wishlist?.find((item) => item._id === product._id);
      // if (foundProduct) {
      //   const newWishListItems = wishlist.filter(
      //     (item) => item._id !== product._id
      //   );
      //   localStorage.setItem("wishlist", JSON.stringify(newWishListItems));
      //   setWishlist(newWishListItems);
      //   toast("Removed from Wishlist", {
      //     icon: <AiFillDelete color="red" style={{ fontSize: "23px" }} />,
      //   });
      // } else {
      //   localStorage.setItem(
      //     "wishlist",
      //     JSON.stringify([...wishlist, { ...product }])
      //   );
      //   setWishlist([...wishlist, { ...product }]);
      //   toast.success("Added to Wishlist.");
      // }
      await axios
        .post(`${keys.PRODUCTION_URL}/product/wishlist`, {
          userId: client?._id,
          products: product._id,
        })
        .then((res) => {
          getWishlist();
          if (res.status === 200) {
            toast.success(res.data);
          }
          if (res.status === 206) toast.error(res.data);
        })
        .catch((error) => {});
    } else {
      toast("Please login");
    }
  };

  const addToBuy = (product) => {
    const addToBuyToCart = buy?.find((i) => i._id === product._id);
    if (!addToBuyToCart) {
      localStorage.setItem("buy", JSON.stringify([...buy, { ...product }]));
      setBuy([...buy, { ...product }]);
    } else {
    }
  };

  const handleCheckout = (value) => {
    const lengthOfValue = Object.keys(value).length;
    if (lengthOfValue !== 6) {
      return setFormError("please fill out all the fields");
    } else {
      setFormError("");
    }
    if (!value.name) {
      return setFormError("Name required");
    }
    if (!value.mobileNumber) {
      return setFormError("Mobile number required");
    }
    if (!value.pincode) {
      return setFormError("Pincode required");
    }
    if (!value.address) {
      return setFormError("Address required");
    }

    const validPincode = pincode.find((p) => {
      return p === value?.pincode;
    });
    if (validPincode) {
      setFormError("");
      if (value.mobileNumber.length === 10) {
        setOrderAddress(value);
        handleOrder(value.mobileNumber);
      } else {
        setFormError("Invalid mobile number");
      }
    } else {
      setFormError("Invalid Pin code please enter valid code!");
    }
  };

  const handleOrder = async (mobileNumber) => {
    let otp = Math.floor(1000 + Math.random() * 999);
    await axios
      .get(
        `https://2factor.in/API/V1/c91b38e2-6976-11ed-9c12-0200cd936042/SMS/${mobileNumber}/AUTOGEN2/classicotps`
        // `https://api.smscountry.com/SMSCwebservice_bulk.aspx?User=AB77987&passwd=e8e8LNNkDpaMXW6&mobilenumber=${mobileNumber}&message=${otp} is your OTP for mobile number verification at Classic. CLSSCC&sid=CLSSCC
        // &mtype=N&DR=Y`
      )
      .then((res) => {
        toast.success("OTP sent", { duration: 5000 });
        setOtps(res.data.OTP);
        setModal2Visible(true);
      })
      .catch((err) => {});
  };

  const getWishlist = async () => {
    await axios
      .get(`${keys.PRODUCTION_URL}/product/wishlist/${client?._id}`)
      .then((res) => {
        setWishlistData(res.data);
      })
      .catch((error) => {});
  };
  // getWishlist();

  // get cart items
  // get cart
  const getAllCart = async () => {
    if (client._id) {
      await axios
        .get(`${keys.PRODUCTION_URL}/cart/all/${client._id}`)
        .then((res) => {
          setCartItemsFromDB(
            res.data.map((data) => data.products.map((product) => product))
          );
        })
        .catch((error) => {});
    }
  };

  // pdf generator

  const generatePdf = () => {
    return (
      <div ref={htmlRef}>
        <h1>Hello World</h1>
        <p>This is some text to be converted to PDF</p>
      </div>
    );
  };
  //

  // get all product
  const getAllProduct = async () => {
    await axios
      .get(`${keys.PRODUCTION_URL}/product/all`)
      .then((res) => {
        setAllProduct(res.data);
      })
      .catch((error) => {});
  };
  return (
    <Context.Provider
      value={{
        cartItems,
        setCart,
        addItemToCart,
        removeItem,
        qty,
        setQty,
        totalPrice,
        Incqty,
        DescQty,
        releventFilter,
        setReleventFilter,
        productData,
        setProductData,
        brand,
        setBrand,
        apiData,
        setApiData,
        filterData,
        setFilterData,
        brandValue,
        setBrandValue,
        user,
        setUser,
        addToWishlist,
        wishlist,
        buy,
        setBuy,
        addToBuy,
        client,
        setClient,
        handleCheckout,
        formError,
        setFormError,
        modal2Visible,
        setModal2Visible,
        setCartItems,
        setTotal,
        redirect,
        setRedirect,
        wishlistData,
        setWishlistData,
        getWishlist,
        otps,
        setOtps,
        orderAddress,
        setOrderAddress,
        paymentType,
        setPaymentType,
        filValue,
        setFilvalue,
        productCategory,
        setProductCategory,
        categoryValue,
        setCategoryValue,
        productByCategory,
        setProductByCategory,
        htmlRef,
        allProduct,
        setAllProduct,
        getAllProduct,
        getAllCart,
        cartFromD,
        filterByBrand,
        setFilterByBrand,
        checkOutLoading,
        setCheckOutLoading,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useStateContext = () => useContext(Context);
