import styled from "styled-components";

export const CategoryContainer = styled.div`
  width: 100%;
  // padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 10px;
`;

export const CategoryWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
`;

export const CategoryItemWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;

  @media only screen and (max-width: 948px) and (min-width: 468px) {
    row-gap: 0.5rem;
    // justify-content: space-evenly;
  }
  @media only screen and (max-width: 468px) and (min-width: 220px) {
    gap: 0.5rem;
    // justify-content: space-evenly;
  }
`;

export const CategoryTitle = styled.h3`
  color: #213038;
  font-size: 24px;
  font-family: Currys Sans Headline, Helvetica, Arial, Sans-Serif;
  @media only screen and (max-width: 468px) and (min-width: 220px) {
    font-size: 20px;
  }
`;
export const CategoryItemContent = styled.div`
  padding: 5px;
  width: 120px;
  height: 120px;
  background-color: #f2f2f2;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  cursor: pointer;
  transition: 0.5s cubic-bezier(0.76, 0, 0.24, 1);
  transform: translateZ(0);
  :hover {
    transform: translateZ(0) scale(1.1);
    transform-origin: center center;
  }
  aspect-ratio: 2/3;
`;
export const CategoryItemDiv = styled.div`
  /* width: 100%; */
  height: 100%;
  width: 150px;
  /* height:150px; */
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  flex-basis: auto;
  align-self: auto;

  @media only screen and (max-width: 948px) and (min-width: 468px) {
    padding: 10px;
    width: 200px;
  }
  @media only screen and (max-width: 468px) and (min-width: 389px) {
    padding: 10px;
    width: 160px;
  }
  @media only screen and (max-width: 389px) and (min-width: 348px) {
    padding: 10px;
    width: 140px;
  }
`;
export const CategoryItemTitle = styled.span`
  margin-top: 20px;
  color: #213038;
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: center;
`;
export const CategoryItemImage = styled.img`
  width: 100%;
  height: 120px;
  border-radius: 50%;
  aspect-ratio: 2/3;
  object-fit: contain;
`;
// export const CategoryWrapper = styled.div``;
// export const CategoryWrapper = styled.div``;
// export const CategoryWrapper = styled.div``;
// export const CategoryWrapper = styled.div``;
// export const CategoryWrapper = styled.div``;
// export const CategoryWrapper = styled.div``;
// export const CategoryWrapper = styled.div``;
// export const CategoryWrapper = styled.div``;
// export const CategoryWrapper = styled.div``;
// export const CategoryWrapper = styled.div``;
// export const CategoryWrapper = styled.div``;
// export const CategoryWrapper = styled.div``;
