import React, { useState, useRef } from "react";
import {
  SettingOutlined,
  CloseOutlined,
  AlignLeftOutlined,
} from "@ant-design/icons";
import { Menu } from "antd";
import { FiPhoneCall } from "react-icons/fi";
import { Link } from "react-router-dom";
import { useStateContext } from "../../contextApi/StateContext";
import {
  CategoryItem,
  SubLeftContainer,
  SubRightContainer,
  SubDivWrapper,
  TopSubNavDiv,
  PhoneLink,
  Phone,
  HamburgerWrapper,
} from "./style";

const items = [
  {
    label: "Home Appliances",
    key: "SubMenu",
    icon: <SettingOutlined />,
  },
];

const wm = (
  <Menu
    items={[
      {
        label: <a href="https://www.antgroup.com">L.G</a>,
        key: "0",
      },
      {
        label: <a href="https://www.aliyun.com">Samsung</a>,
        key: "1",
      },
      {
        label: <a href="https://www.aliyun.com">Whirlpool</a>,
        key: "2",
      },
      {
        label: <a href="https://www.aliyun.com">Godrej</a>,
        key: "3",
      },
    ]}
  />
);

export default function TopSubNav() {
  const [active, setActive] = useState();
  const { setFilvalue } = useStateContext();
  const nav2Ref = useRef();

  const handleActive = (e) => {
    e.preventDefault();
  };
  const handleBg = (e) => {
    if (active === e) {
      setActive(null);
    } else {
      setActive(e);
    }
  };

  //
  const menu = (
    <Menu
      items={[
        {
          label: <a href="https://www.antgroup.com">L.G</a>,
          key: "0",
        },
        {
          label: <a href="https://www.aliyun.com">Samsung</a>,
          key: "1",
        },
        {
          label: <a href="https://www.aliyun.com">Whirlpool</a>,
          key: "2",
        },
        {
          label: <a href="https://www.aliyun.com">Godrej</a>,
          key: "3",
        },
      ]}
    />
  );
  const rf = (
    <Menu
      items={[
        {
          label: <a href="https://www.antgroup.com">L.G</a>,
          key: "0",
        },
        {
          label: <a href="https://www.aliyun.com">Samsung</a>,
          key: "1",
        },
        {
          label: <a href="https://www.aliyun.com">Whirlpool</a>,
          key: "2",
        },
        {
          label: <a href="https://www.aliyun.com">Godrej</a>,
          key: "3",
        },
      ]}
    />
  );
  const mb = (
    <Menu
      items={[
        {
          label: <a href="#">L.G</a>,
          key: "0",
        },
        {
          label: <a href="#">Samsung</a>,
          key: "1",
        },
        {
          label: <a href="#">Whirlpool</a>,
          key: "2",
        },
        {
          label: <a href="#">Godrej</a>,
          key: "3",
        },
      ]}
    />
  );
  const ac = (
    <Menu
      items={[
        {
          label: <a href="https://www.antgroup.com">L.G</a>,
          key: "0",
        },
        {
          label: <a href="https://www.aliyun.com">Samsung</a>,
          key: "1",
        },
        {
          label: <a href="https://www.aliyun.com">Whirlpool</a>,
          key: "2",
        },
        {
          label: <a href="https://www.aliyun.com">Godrej</a>,
          key: "3",
        },
      ]}
    />
  );
  const ka = (
    <Menu
      items={[
        {
          label: (
            <h3
              style={{ textAlign: "center", color: "grey", fontWeight: "400" }}
            >
              <span>Sony</span>
            </h3>
          ),
          key: "0",
        },
        {
          label: <a href="https://www.aliyun.com">L.G</a>,
          key: "1",
        },
        {
          label: <a href="https://www.aliyun.com">Samsung</a>,
          key: "2",
        },
      ]}
    />
  );
  const tv = (
    <Menu
      items={[
        {
          label: (
            <h3
              style={{ textAlign: "center", color: "grey", fontWeight: "400" }}
            >
              <span>Sony</span>
            </h3>
          ),
          key: "0",
        },
        {
          label: <a href="https://www.aliyun.com">L.G</a>,
          key: "1",
        },
        {
          label: <a href="https://www.aliyun.com">Samsung</a>,
          key: "2",
        },
      ]}
    />
  );

  //

  const Category = [
    {
      id: 1,
      name: "kitchen & Other Appliance",
      itemss: menu,
      value: "HA",
      position: "bottom",
      category_name: "kitchen&otherappliances",
    },
    {
      id: 2,
      name: "Washing Machine",
      itemss: wm,
      value: "WM",
      position: "bottom",
      category_name: "washing_machine",
    },
    {
      id: 3,
      name: "Refrigerator",
      itemss: rf,
      value: "RF",
      position: "bottomLeft",
      category_name: "refrigerator",
    },
    {
      id: 4,
      name: "Mobile Phones",
      itemss: rf,
      value: "M",
      position: "bottomLeft",
      category_name: "mobile_phone",
    },
    {
      id: 5,
      name: "LED TV & Audio",
      itemss: tv,
      value: "TV",
      position: "bottomLeft",
      category_name: "tv",
    },
    {
      id: 6,
      name: "Laptop",
      itemss: tv,
      value: "TV",
      position: "bottomLeft",
      category_name: "laptop",
    },
    {
      id: 7,
      name: "Air Conditioners",
      itemss: ac,
      value: "Air Conditioners",
      position: "bottomLeft",
      category_name: "ac",
    },
    // {
    //   id: 8,
    //   name: "Kitchen Appliances",
    //   itemss: ka,
    //   value: "AC",
    //   position: "bottomLeft",
    //   category_name: "kitchen_appliances",
    // },
  ];

  const showNavbar = () => {
    nav2Ref.current.classList.toggle("responsive-nav");
  };

  return (
    <TopSubNavDiv>
      <HamburgerWrapper className="nav-btn">
        <AlignLeftOutlined onClick={showNavbar} />
      </HamburgerWrapper>
      <SubLeftContainer ref={nav2Ref}>
        {Category.map((item, idx) => (
          <Link
            to={`/product/${item.category_name}`}
            onClick={() => showNavbar()}
          >
            <SubDivWrapper onClick={(e) => handleBg(item.id)}>
              <span
                className="categoryItemname"
                onClick={(e) => {
                  handleActive(e);
                  setFilvalue("");
                  setActive(item.name);
                }}
              >
                <Link to={`/product/${item.category_name}`}>
                  <CategoryItem>{item.name}</CategoryItem>
                </Link>
              </span>
            </SubDivWrapper>
          </Link>
        ))}
        <HamburgerWrapper className="nav-btn nav-close-btn">
          <CloseOutlined onClick={() => showNavbar()} />
        </HamburgerWrapper>
      </SubLeftContainer>
      <SubRightContainer>
        <PhoneLink href="tel:+918453925376">
          <Phone>
            <FiPhoneCall /> +91-8453925376
          </Phone>
        </PhoneLink>
      </SubRightContainer>
    </TopSubNavDiv>
  );
}
