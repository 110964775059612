import styled from "styled-components";

export const BannerTwoContainer = styled.div`
  width: 100%;
  margin-top: 30px;
  padding: 0px 30px;

  @media (max-width: 768px) {
    padding: 0px;
  }
`;

export const BannerOneContainer = styled.div`
  width: 100%;
  position: relative;
`;
export const ShopNowBtnWrapper = styled.div`
  position: absolute;
  right: 15%;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    right: 1%;
    bottom: 2% !important;
  }
`;
export const ShopNowBtn = styled.button`
  border: none;
  padding: 10px;
  margin-top: 30px;
  width: 100px;
  font-weight: 700;
  border-radius: 20px;

  @media (max-width: 768px) {
    width: 80px;
    margin-top: 0px;
    padding: 5px;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.8px;
  }
`;

export const BannerImage = styled.img`
  width: 100%;
  height: 500px;

  @media (max-width: 768px) {
    height: 250px;
    aspect-ratio: 2/3;
    object-fit: contain;
  }
`;

// export const BannerTwoContainer = styled.div``;
// export const BannerTwoContainer = styled.div``;
// export const BannerTwoContainer = styled.div``;
// export const BannerTwoContainer = styled.div``;
// export const BannerTwoContainer = styled.div``;
// export const BannerTwoContainer = styled.div``;
// export const BannerTwoContainer = styled.div``;
// export const BannerTwoContainer = styled.div``;
// export const BannerTwoContainer = styled.div``;
// export const BannerTwoContainer = styled.div``;
// export const BannerTwoContainer = styled.div``;
// export const BannerTwoContainer = styled.div``;
// export const BannerTwoContainer = styled.div``;
// export const BannerTwoContainer = styled.div``;
// export const BannerTwoContainer = styled.div``;
// export const BannerTwoContainer = styled.div``;
// export const BannerTwoContainer = styled.div``;
// export const BannerTwoContainer = styled.div``;
// export const BannerTwoContainer = styled.div``;
// export const BannerTwoContainer = styled.div``;
// export const BannerTwoContainer = styled.div``;
// export const BannerTwoContainer = styled.div``;
// export const BannerTwoContainer = styled.div``;
// export const BannerTwoContainer = styled.div``;
// export const BannerTwoContainer = styled.div``;
// export const BannerTwoContainer = styled.div``;
// export const BannerTwoContainer = styled.div``;
// export const BannerTwoContainer = styled.div``;
// export const BannerTwoContainer = styled.div``;
// export const BannerTwoContainer = styled.div``;
// export const BannerTwoContainer = styled.div``;
// export const BannerTwoContainer = styled.div``;
// export const BannerTwoContainer = styled.div``;
