import { Button } from "antd";
import React from "react";
import { useState } from "react";
import OTPInput from "otp-input-react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useStateContext } from "../../contextApi/StateContext";
import axios from "axios";
import * as keys from "../../config/keys";
const FormComponent = ({ seconds, setSeconds }) => {
  const [otp, setOtp] = useState();

  const [otpError, setOtpError] = useState("");
  const [otpSuccess, setOtpSuccess] = useState("");
  const navigate = useNavigate();
  const {
    setModal2Visible,
    setCartItems,
    setTotal,
    cartItems,
    client,
    otps,
    orderAddress,
    paymentType,
    checkOutLoading,
    setCheckOutLoading,
  } = useStateContext();

  const handleChange = (value) => {
    setOtp(value);
  };

  const ResendOtpCall = () => {
    setSeconds(30);
    toast.success("OTP Resend");
  };
  const handleOtpVerify = async () => {
    setCheckOutLoading(true);
    if (!otp || otp.length !== 6) {
      setCheckOutLoading(false);
    }
    if (!otp || otp.length !== 6) return setOtpError("Please enter otp");

    setOtpError("");
    if (Number(otp) === Number(otps)) {
      const getOrdersId = cartItems?.map((i) => {
        return i._id;
      });

      const sendOrder = await axios
        .post(`${keys.PRODUCTION_URL}/product/order/${client._id}`, {
          productId: getOrdersId,
          userId: client?._id,
          productDetail: cartItems,
          orderAddress: orderAddress,
          paymentType: paymentType,
        })
        .then((res) => {
          if (res.status === 200) {
            axios
              .post(`${keys.PRODUCTION_URL}/product/delete/cart`, {
                userId: res.data.userId,
              })
              .then((res) => {})
              .catch((err) => {
                axios
                  .post(`${keys.PRODUCTION_URL}/product/delete/cart`, {
                    userId: res.data.userId,
                  })
                  .then((res) => {})
                  .catch((err) => {
                    axios.post(`${keys.PRODUCTION_URL}/product/delete/cart`, {
                      userId: res.data.userId,
                    });
                  });
              });
            if (res.data.orderNumber) {
              axios.get(`https://2factor.in/API/R1/?module=PROMO_SMS&apikey=c91b38e2-6976-11ed-9c12-0200cd936042&to=${orderAddress.mobileNumber}&from=CLSSCC&msg=Hello ${orderAddress.name}, your order number ${res.data.orderNumber} with classic is placed. Thank you for shopping with classic.
              `);
            }
          }
          toast.success("Order placed success");
          setOtp();
          localStorage.setItem("cart", []);
          setTotal(0);
          setCartItems([]);
          navigate(`/order/success/${res.data._id}`);
        })
        .catch((err) => {
          setOtp();
          setOtpSuccess("");
          toast.error("Something went wrong please try again!");
        });

      setModal2Visible(false);
      setCheckOutLoading(false);
    } else {
      setCheckOutLoading(false);

      toast.error("Invalid OTP");
      setOtpError("Invalid otp");
    }
  };

  return (
    <>
      {otpError && (
        <span
          style={{
            width: "100%",
            backgroundColor: "red",
            color: "white",
            padding: "10px",
          }}
        >
          {otpError}
        </span>
      )}
      {otpSuccess && (
        <span
          style={{
            width: "100%",
            backgroundColor: "lightgreen",
            color: "black",
            padding: "10px",
            fontSize: "16px",
            fontFamily: "Roboto",
            fontWeight: "500",
          }}
        >
          {" "}
          {otpSuccess}
        </span>
      )}
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          rowGap: "20px",
          marginBottom: "20px",
        }}
      >
        <OTPInput
          value={otp}
          onChange={(value) => {
            setOtpError("");
            setOtpSuccess("");
            setOtp(value);
          }}
          autoFocus
          OTPLength={6}
          otpType="number"
          disabled={false}
          style={{
            padding: "20px",
          }}
        />
        {/* <ResendOTP
          inputStyles={{ padding: "10px" }}
          style={{ padding: "10px", width: "50%", border: "none" }}
          onResendClick={() => console.log("Resend clicked")}
        /> */}
      </div>
      {/* <span>{seconds > 0 ? `Resend Code in (${seconds}s)` : `Resend otp`}</span> */}
      {/* <Button
        type="primary"
        style={{
          width: "50%",
          marginTop: "20px",
          borderRadius: "20px",
          height: "40px",
        }}
        htmlType="submit"
        className="login-form-button"
        danger
        disabled={seconds > 0}
        onClick={() => ResendOtpCall()}
      >
        {seconds > 0 ? `Resend Code in (${seconds}s)` : `Resend otp`}
      </Button> */}
      <Button
        type="primary"
        style={{
          width: "100%",
          marginTop: "20px",
          height: "50px",
          fontSize: "20px",
          fontFamily: "Roboto",
          fontWeight: "500",
        }}
        disabled={checkOutLoading ? true : false}
        htmlType="submit"
        className="login-form-button"
        onClick={() => handleOtpVerify()}
      >
        <span>Verify otp & place order</span>
      </Button>
    </>
  );
};

export default FormComponent;
