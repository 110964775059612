import React from "react";
import Iframe from "react-iframe";

export default function Map() {
  return (
    <div
      //  className="mapWrapper"
      style={{ width: "100%" }}
    >
      <Iframe
        // src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d827.8834368805791!2d91.77008602757611!3d26.184108156061203!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x375a5999e0210b37%3A0x9cd2484b06b73595!2sClassic%20Electronic%20Showroom!5e0!3m2!1sen!2sin!4v1664530264598!5m2!1sen!2sin"
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d223.77382610525677!2d91.76984244817677!3d26.184274531920448!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x375a5999e0210b37%3A0x9cd2484b06b73595!2sClassic%20Electronic%20Showroom!5e0!3m2!1sen!2sin!4v1664542227336!5m2!1sen!2sin"
        width="100%"
        height="400"
        // style="border:0;"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></Iframe>
    </div>
  );
}
