import styled from "styled-components";

export const CardConatiner = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const CardWrapper = styled.div`
  /* padding: 15px; */
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* margin-left: 20px; */
  position: relative;
  flex-wrap: wrap;
  width: 100%;

  @media only screen and (max-width:1148px) and (min-width:868px){
    gap:1rem;
  }
  @media only screen and (max-width:868px) and (min-width:711px) {
    gap:0.8rem;
  }
  @media only screen and (max-width:711px) and (min-width:548px)  {
    gap:0.6rem;
  }
  @media only screen and (max-width:548px)  {
    gap:0.5rem;
  }

`;
export const CardItem = styled.div`
  width: 270px;
  height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px 15px;
  box-shadow: 0 5px 15px rgb(0 0 0 / 5%);
  :hover {
    transition: box-shadow 0.35s ease;
    /* border: 1px solid grey; */
  }
`;
export const CardImageSection = styled.div`
  width: 100%;
  height: 60%;
  position: relative;
`;
export const Images = styled.img`
  width: 100%;
  height: 100%;
`;
export const CardTitleSection = styled.div`
  width: 100%;
  height: 30%;
  display: flex;
  align-items: start;
  justify-content: start;
  flex-direction: column;
`;
export const ItemTitle = styled.span`
  color: #333;
  font-size: 16px;
  /* font-family: "Lato"; */
  font-weight: 400;
`;
export const ItemPrice = styled.span`
  font-size: 14px;
  font-family: "Roboto";
  font-weight: 600;
  color: grey;
`;
export const Button = styled.button`
  width: 80%;
  padding: 5px 10px;
  border: none;
  /* background-color: #39f; */
  background-color: lightseagreen;
  cursor: pointer;
  border-radius: 20px;
  color: white;
`;
export const ButtonDiv = styled.div`
  width: 100%;
  display: flex;
  margin-top: 15px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
// export const CardConatiner = styled.div``;
// export const CardConatiner = styled.div``;
// export const CardConatiner = styled.div``;
// export const CardConatiner = styled.div``;
// export const CardConatiner = styled.div``;
// export const CardConatiner = styled.div``;
// export const CardConatiner = styled.div``;
// export const CardConatiner = styled.div``;
// export const CardConatiner = styled.div``;
// export const CardConatiner = styled.div``;
// export const CardConatiner = styled.div``;
// export const CardConatiner = styled.div``;
// export const CardConatiner = styled.div``;

export const HeartIconContainer = styled.div`
  position: absolute;
  width: 30px;
  top: 0px;
  right: 5px;
  z-index: 1;
  /* display: none; */
  /* :hover {
    display: block;
  } */
`;
