import styled from "styled-components";

export const ContactContainer = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #102349;
  /* background-color: #d8efef; */
  margin-top: 40px;

  @media screen and (max-width: 721px) {
    height: 350px;
  }
`;

export const ContactMainTitle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  font-weight: 500;
  font-family: sans-serif;
  padding: 5px 0px;
  color: white;

  @media screen and (max-width: 721px) and (min-width: 481px) {
    font-size: 28px;
  }
  @media screen and (max-width: 481px) and (min-width: 321px) {
    font-size: 24px;
  }
`;

export const ContactWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const ContactDivWrapper = styled.div`
  width: 100%;
  height: 60%;
  display: flex;
  align-items: center;
  text-align: center;

  @media screen and (min-width: 721px) {
    justify-content: center;
  }
  @media screen and (max-width: 721px) {
    flex-wrap: wrap;
    height: 100%;
    gap: 0.5rem;
  }
`;

export const ContactContent = styled.div`
  width: 33%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media screen and (max-width: 721px) {
    width: 48%;
    height: 50%;
  }
`;

export const ContactContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* justify-content: ${(props) => (props.top ? `space-evenly` : `center`)}; */
  /* margin-top: ${(props) => props.mt && `60px`}; */
  flex-direction: column;
  @media screen and (max-width: 721px) {
    /* height: 100%; */
    /* justify-content: center; */
  }
`;

export const ContactTitle = styled.span`
  font-size: 26px;
  font-weight: 600;
  font-family: "Roboto";
  color: white;
  text-decoration: underline;

  @media screen and (max-width: 721px) and (max-width: 481px) {
    font-size: 22px;
  }
  @media screen and (max-width: 481px) {
    font-size: 20px;
  }
`;

export const ContactDetails = styled.span`
  width: 80%;
  /* margin-top: 10px; */
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: white;

  @media screen and (max-width: 721px) {
    width: 100%;
    font-size: 14px;
    flex-direction: column;
  }
`;

// export const ContactContainer = styled.div``;
// export const ContactContainer = styled.div``;
// export const ContactContainer = styled.div``;
// export const ContactContainer = styled.div``;
// export const ContactContainer = styled.div``;
// export const ContactContainer = styled.div``;
// export const ContactContainer = styled.div``;
// export const ContactContainer = styled.div``;
// export const ContactContainer = styled.div``;
// export const ContactContainer = styled.div``;
// export const ContactContainer = styled.div``;
// export const ContactContainer = styled.div``;
// export const ContactContainer = styled.div``;
// export const ContactContainer = styled.div``;
// export const ContactContainer = styled.div``;
// export const ContactContainer = styled.div``;
// export const ContactContainer = styled.div``;
// export const ContactContainer = styled.div``;
// export const ContactContainer = styled.div``;
// export const ContactContainer = styled.div``;
// export const ContactContainer = styled.div``;
// export const ContactContainer = styled.div``;
// export const ContactContainer = styled.div``;
// export const ContactContainer = styled.div``;
// export const ContactContainer = styled.div``;
// export const ContactContainer = styled.div``;
