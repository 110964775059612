import styled from "styled-components";

export const ModalInnerContainer = styled.div`
  width: 100%;
  /* background-color: grey; */
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
`;

export const Title = styled.span`
  font-size: 25px;
`;
