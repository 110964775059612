import axios from "axios";
import React, { createContext, useContext } from "react";
import { useState } from "react";
import * as keys from "../config/keys";
const Context = createContext();

export const ChekOutContext = ({ children }) => {
  const [billingDetails, setBillingDetails] = useState();
  const [currentTab, setCurrentTab] = useState("Dashboard");
  const [orderNo, setOrderNo] = useState("");
  const [orderNumber, setOrderNumber] = useState("");
  const [tractOrderData, setTrackOrderData] = useState("");
  const handleCheckout = () => {};

  const handleTrackProduct = async (orderNumber, id) => {
    setOrderNumber(orderNumber);
    await axios
      .get(`${keys.PRODUCTION_URL}/product/track/product/${id}/${orderNumber}`)
      .then((res) => {
        setTrackOrderData(res.data);
      })
      .catch((err) => {});
  };

  const handleCarncelOrder = async (id, clientId) => {
    await axios
      .post(`${keys.PRODUCTION_URL}/auth/update/order/cancel/${id}/${clientId}`)
      .then((res) => {
        handleTrackProduct(orderNumber, clientId);
      })
      .catch((err) => {});
  };

  return (
    <Context.Provider
      value={{
        handleCheckout,
        billingDetails,
        setBillingDetails,
        currentTab,
        setCurrentTab,
        orderNo,
        setOrderNo,
        handleTrackProduct,
        tractOrderData,
        handleCarncelOrder,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useChekOutContext = () => useContext(Context);
