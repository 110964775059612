import React, { useState } from "react";
import { CardConatiner, CardWrapper } from "./style";
import * as keys from "../../config/keys";
import ProductDetailCard from "../ProductDetailCard";
import { useEffect } from "react";
import axios from "axios";
import { useStateContext } from "../../contextApi/StateContext";
export default function Cards() {
  const [data, setData] = useState([]);
  const { getWishlist } = useStateContext();
  useEffect(() => {
    const getProduct = async () => {
      const res = await axios.get(`${keys.PRODUCTION_URL}/product/popular`);
      setData(res.data);
    };
    getProduct();
    getWishlist();
  }, []);

  return (
    <CardConatiner>
      <CardWrapper>
        {data?.slice(0, 5).map((item, id) => (
          <ProductDetailCard w item={item} id={id} />
        ))}
      </CardWrapper>
    </CardConatiner>
  );
}
