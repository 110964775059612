import styled from "styled-components";

export const CarouselContainer = styled.div`
  position: relative;
  width: 100%;
  /* height: 700px; */
  @media only screen and (max-width: 781px) and (min-width: 481px) {
    width: 100%;
    height: 300px;
  }
`;

export const CarouselTextBlock = styled.div`
  position: absolute;
  bottom: 8em;
  right: 5em;
  ${(props) => props.position === "right" && `right:3em;`}
  ${(props) =>
    props.position === "center" &&
    `
  
  left:50%;
  

   `}
  ${(props) => props.position === "left" && `left:4em; `}
  /* ${(props) => props.position === "left" && `bottom:12em; `} */
  color: white;
  /* padding-left: 20px; */
  /* padding-right: 20px; */

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    right: 1rem;
    bottom: 1rem;
    ${(props) =>
      props.position === "center" &&
      `
  bottom:20%;
  left:48%
   `}
  }
  @media screen and (max-width: 768px) {
    right: 1rem;
    bottom: 1rem;
    ${(props) =>
      props.position === "center" &&
      `
  bottom:20%;
  left:43%
   `}
    ${(props) =>
      props.position === "left" &&
      `
  bottom:25%;
  left:10%
   `}
  }
`;
export const CarouselImagesWrapper = styled.div`
  width: 100%;
  height: 500px;
  @media screen and (max-width: 768px) {
    height: 300px;
  }
`;
export const CarouselImages = styled.img`
  height: 100%;
  color: #fff;
  text-align: center;
  background: #364d79;
  width: 100%;
  object-fit: fill;

  @media screen and (max-width: 768px) {
    height: 300px;
  }
`;

export const SliderText = styled.div`
  position: absolute;
  top: 35%;
  right: auto;
  left: auto;
  width: 100%;
  /* font-size: 30px; */
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props) => props.position === "left" && ``}
  ${(props) =>
    props.position === "center" &&
    `
   right: auto;
  left: 4%;
  `}
  ${(props) =>
    props.position === "right" &&
    `
   right:-16%;
  `}
`;
export const SliderTextItem = styled.div`
  width: 29%;
  font-size: 30px;
  font-weight: bolder;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 768px) {
    font-size: 16px;
    width: 35%;
  }
`;
// export const CarouselContainer = styled.div``;
// export const CarouselContainer = styled.div``;
// export const CarouselContainer = styled.div``;
// export const CarouselContainer = styled.div``;
// export const CarouselContainer = styled.div``;
// export const CarouselContainer = styled.div``;
// export const CarouselContainer = styled.div``;
// export const CarouselContainer = styled.div``;
// export const CarouselContainer = styled.div``;
// export const CarouselContainer = styled.div``;
// export const CarouselContainer = styled.div``;
// export const CarouselContainer = styled.div``;
// export const CarouselContainer = styled.div``;
// export const CarouselContainer = styled.div``;
// export const CarouselContainer = styled.div``;
// export const CarouselContainer = styled.div``;
// export const CarouselContainer = styled.div``;
// export const CarouselContainer = styled.div``;
// export const CarouselContainer = styled.div``;
// export const CarouselContainer = styled.div``;
// export const CarouselContainer = styled.div``;
// export const CarouselContainer = styled.div``;
// export const CarouselContainer = styled.div``;
// export const CarouselContainer = styled.div``;
// export const CarouselContainer = styled.div``;
// export const CarouselContainer = styled.div``;
// export const CarouselContainer = styled.div``;
// export const CarouselContainer = styled.div``;
