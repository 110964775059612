import { Button, Carousel } from "antd";
import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import DiwaliTvImage from "../../images/banner/Diwali-festive-banner.avif";
import washingMachineImage from "../../images/banner/washingmachines.jpg";
import LGDiwali from "../../images/banner/banner-oct-23/lg-diwali.jpg";
import lgRef from "../../images/banner/banner-oct-23/lg-ref.jpg";
import diswasher from "../../images/banner/DISHWASHER.avif";
import BlueStarAc from "../../images/banner/ac.jpg";
import SliderRefrigerator from "../../images/banner/ref-slider.webp";

import {
  CarouselContainer,
  CarouselTextBlock,
  CarouselImages,
  SliderText,
  SliderTextItem,
  CarouselImagesWrapper,
} from "./syle";
import { Link } from "react-router-dom";
import axios from "axios";
import { IMAGE_URL_PRODUCTION, PRODUCTION_URL } from "../../config/keys";
import { useState } from "react";

const Slider = () => {
  const [bannerData, setBannerData] = useState();

  const btnStyles = {
    border: "none",
    padding: "5px 20px",
    fontFamily: "cursive",
    // backgroundColor: "black",
    backgroundColor: "#146cda",
    color: "white",
    borderRadius: "20px",
  };

  const bannerImages = [
    {
      img: DiwaliTvImage,
      url: "/product/tv",
      position: "right",
      text: "",
    },
    {
      img: diswasher,
      url: "/product/home&kitchenappliances",
      position: "right",
    },
    {
      img: washingMachineImage,
      url: "/product/washing_machine",
      position: "left",
      left: "",
    },

    {
      img: LGDiwali,
      url: "/product/lg",
      position: "right",
      text: "",
    },
    {
      img: lgRef,
      url: "/product/refrigerator",
      position: "right",
      text: "",
    },
    {
      img: BlueStarAc,
      url: "/product/ac",
      position: "center",
      text: " Powerful cooling & WindFree™ comfort",
    },
  ];

  const getBanner = async () => {
    await axios
      .get(`${PRODUCTION_URL}/product/banner/list`)
      .then((res) => {
        setBannerData(res.data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    AOS.init();
    AOS.refresh();
    getBanner();
  }, []);


  return (
    <Carousel autoplay fade>
      {bannerData &&
        bannerData[0]?.images.map((sliderImg, id) => (
          <CarouselContainer key={id}>
            <CarouselImagesWrapper>
              <CarouselImages
                src={`${IMAGE_URL_PRODUCTION}/${sliderImg}`}
                alt={sliderImg}
              />
            </CarouselImagesWrapper>
            {/* <SliderText position={sliderImg.position}>
              <SliderTextItem>{sliderImg.text}</SliderTextItem>
            </SliderText> */}
            {/* {sliderImg.position && ( */}
            <CarouselTextBlock
            // position={sliderImg.position}
            >
              <Link to={`/product/${bannerData[0]?.category[id]}`}>
                <Button style={btnStyles}>Shop now</Button>
              </Link>
            </CarouselTextBlock>
            {/* )} */}
          </CarouselContainer>
        ))}
    </Carousel>
  );
};

export default Slider;
