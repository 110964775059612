import React from 'react'
import {TitleHeader,HeaderTitle,
    MoreIconWrapper ,MoreTitle
} from "./style.js"
import { ArrowRightOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

const ProductHeader = () => {
  return (
    <TitleHeader>
        <HeaderTitle>{"Popular Product"}</HeaderTitle>
        <MoreIconWrapper>
            <Link to={"/product/popular_product"} style={{ display: "flex" }}>
              <MoreTitle>
                 More
                <ArrowRightOutlined
                  style={{
                    marginLeft: "10px",
                    color: "#146cda",
                    cursor: "pointer",
                  }}
                />
              </MoreTitle>
            </Link>
          </MoreIconWrapper>
    </TitleHeader>
  )
}

export default ProductHeader