import styled from "styled-components";

export const LatestProudctWrapper = styled.div`
  width: 100%;
  /* padding: 20px 40px; */
  display: flex;
  flex-direction: column;
`;

export const LatestProductContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const DiscoverTitleContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  padding-right: 20px;

  @media screen and (max-width: 768px) {
    padding-right: 0px;
  }
`;
export const LatestProductTitle = styled.h3`
  width: 60%;
  /* font-family: "Currys Sans Headline", Helvetica, Arial, Sans-Serif; */
  font-family: "Roboto";
  font-size: 20px;
  font-weight: 600;
  color: #213038;

  @media only screen and (min-width: 468px) and (max-width: 768px) {
    width: 100%;
    font-size: 18px;
  }
  @media screen and (max-width: 468px) and (min-width: 368px) {
    width: 100%;
    font-size: 1rem;
  }
  @media screen and (max-width: 368px) {
    width: 100%;
    font-size: 0.8rem;
  }
`;
export const More = styled.span`
  /* width: 100%; */
  cursor: pointer;
  color: #146cda;
  text-align: right;
  font-size: 16px;
  font-family: "Roboto Mono";
  display: ${(props) => (props.dh ? `block` : `none`)};
  /* display: hidden; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  border: 1px solid #e0e2e9;
  border-radius: 10px;
  padding: 3px 5px;

  @media screen and (max-width: 368px) {
    font-size: 14px;
  }
`;
export const LatestProductCardSection = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* gap: 10px; */
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 10px;
  row-gap: 5px;
`;
export const LatestProductCard = styled.div`
  width: 16%;
  height: ${(props) => props.relatedProduct && `auto`};
  position: relative;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  /* gap:10px;  */
  /* box-shadow: rgba(99, 99, 99, 0.3) 0px 2px 8px 0px;
  column-gap: 10px; */
  /* box-shadow: rgba(99, 99, 99, 0.3) 0px 2px 8px 0px; */

  @media (max-width: 1148px) and (min-width: 921px) {
  }
  @media (max-width: 961px) and (min-width: 768px) {
    width: 24%;
    /* height: 30vh; */
  }
  @media (max-width: 768px) and (min-width: 468px) {
    width: 48%;
    /* height: 30vh; */
  }
  @media (max-width: 468px) and (min-width: 348px) {
    /* height: 30vh; */
    width: 48%;
    /* height: 300px; */
  }
  @media (max-width: 348px) {
    /* height: auto; */
    width: 100%;
  }
  /* height: "400px"; */

  /* border: 1px solid blue; */
  /* background-color: black; */
  /* ${(props) => props.color && `background-color:${props.color}`} */
  transition: box-shadow 0.4s;
  /* :hover {
    -webkit-box-shadow: 9px 4px 21px 11px rgba(232, 232, 232, 1);
    -moz-box-shadow: 9px 4px 21px 11px rgba(232, 232, 232, 1);
    box-shadow: 9px 4px 21px 11px rgba(232, 232, 232, 1);
  } */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  transition: all 0.35s;
  /* transition: transform 0.35s; */
  :hover {
    ${(props) =>
      props.transformNone
        ? `transforn:none`
        : `transform: scale(1)`}/* transform: scale(1.1); */
  }
`;
export const ViewProductDiv = styled.div`
  width: 100%;
  /* background-color: grey; */
  display: flex;
  align-items: center;
  justify-content: center;

  /* position: relative; */
`;
export const ViewTitle = styled.button`
  width: 100%;
  border: none;
  cursor: pointer;
  padding: 10px 15px;
  color: black;
  background-color: #fff;
  border: 1px solid black;
  border-radius: 30px;
  /* background-color: black; */
  cursor: pointer;
  transition: all 0.3s;
  :hover {
    /* background-color: lightseagreen; */
    background-color: black;
    color: white;
  }

  /* background-color: lightseagreen; */
`;

export const HoverButton = styled.button`
  padding: 0.8em 1.8em;
  border: 2px solid #17c3b2;
  position: relative;
  overflow: hidden;
  background-color: transparent;
  text-align: center;
  text-transform: uppercase;
  font-size: 16px;
  transition: 0.3s;
  z-index: 1;
  font-family: inherit;
  color: #17c3b2;
  ::before {
    content: "";
    width: 0;
    height: 300%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    background: #17c3b2;
    transition: 0.5s ease;
    display: block;
    z-index: -1;
  }
  :hover::before {
    width: 105%;
  }

  :hover {
    color: #111;
  }
`;
export const ProductTitle = styled.span`
  margin-top: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  text-align: center;
  font-weight: bold;
`;

export const PriceWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
`;
export const OffContainer = styled.div`
  position: absolute;
  top: 10px;
  left: 15px;
  background-color: #1800be;
  color: white;
  padding: 5px;
  font-weight: 600;
  z-index: 99;
  @media (max-width: 921px) and (min-width: 768px) {
  }
  @media (max-width: 768px) {
    padding: 3px 4px;
    border-radius: 10px;
    font-size: 12px;
  }
`;
export const ProductImageWrapper = styled.div`
  width: 100%;
  height: 200px;
  /* padding: 10px; */
`;
export const LatestProductImage = styled.img`
  width: 100%;
  height: 100%;
  /* object-fit: contain; */
  object-fit: contain;
  aspect-ratio: 2/3;
  transition: all 0.35s;
  /* transition: transform 0.35s; */
  :hover {
    /* transform: scale(1.1); */
  }
  @media (max-width: 1148px) {
  }
  @media (max-width: 1148px) and (min-width: 921px) {
  }
  @media (max-width: 921px) and (min-width: 768px) {
  }
  @media (max-width: 768px) and (min-width: 468px) {
  }
  @media (max-width: 468px) and (min-width: 228px) {
  }
`;
export const HoverContainer = styled.div`
  position: absolute;
  top: 60px;
  right: 10px;
  /* display: ${(props) => (props.hover ? `block` : `none`)}; */
  display: none;
`;
// export const LatestProudctWrapper=styled.div``
// export const LatestProudctWrapper=styled.div``
// export const LatestProudctWrapper=styled.div``
// export const LatestProudctWrapper=styled.div``
// export const LatestProudctWrapper=styled.div``
// export const LatestProudctWrapper=styled.div``
// export const LatestProudctWrapper=styled.div``
// export const LatestProudctWrapper=styled.div``
// export const LatestProudctWrapper=styled.div``
// export const LatestProudctWrapper=styled.div``
// export const LatestProudctWrapper=styled.div``
// export const LatestProudctWrapper=styled.div``
// export const LatestProudctWrapper=styled.div``
// export const LatestProudctWrapper=styled.div``
// export const LatestProudctWrapper=styled.div``
// export const LatestProudctWrapper=styled.div``
// export const LatestProudctWrapper=styled.div``
// export const LatestProudctWrapper=styled.div``
// export const LatestProudctWrapper=styled.div``
// export const LatestProudctWrapper=styled.div``
// export const LatestProudctWrapper=styled.div``
// export const LatestProudctWrapper=styled.div``
// export const LatestProudctWrapper=styled.div``
// export const LatestProudctWrapper=styled.div``
// export const LatestProudctWrapper=styled.div``
// export const LatestProudctWrapper=styled.div``
// export const LatestProudctWrapper=styled.div``
// export const LatestProudctWrapper=styled.div``
// export const LatestProudctWrapper=styled.div``
// export const LatestProudctWrapper=styled.div``
