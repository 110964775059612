import React from "react";
import Carousel from "react-multi-carousel";

import "./style.css";
import { CartButton } from "../ProductDetailCard/style";
import axios from "axios";
import * as keys from "../../config/keys";
import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import formatIndianNumber from "../common/CurrencyInr";

const TopProducts = () => {
  const [topProductData, setTopProduct] = useState();

  const getTopProduct = async () => {
    await axios
      .get(`${keys.PRODUCTION_URL}/product/get/top/product`)
      .then((res) => {
        setTopProduct(res.data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getTopProduct();
  }, []);

  return (
    <div
      style={{
        width: "100%",
        padding: "40px",
        // display: "flex",
        // justifyContent: "center",
        // alignItems: "center",
      }}
    >
      {topProductData?.length > 0 && (
        <>
          <div
            style={{
              width: "100%",
            }}
          >
            <span className="top-product">Top Products</span>
            {/* <hr /> */}
          </div>
          <Carousel
            additionalTransfrom={0}
            autoPlay
            arrows={false}
            autoPlaySpeed={1500}
            centerMode={false}
            className=""
            containerClass="container-with-dots"
            dotListClass=""
            draggable
            focusOnSelect={false}
            infinite
            itemClass=""
            keyBoardControl
            minimumTouchDrag={80}
            pauseOnHover
            renderArrowsWhenDisabled={false}
            renderButtonGroupOutside={false}
            renderDotsOutside={false}
            responsive={{
              desktop: {
                breakpoint: {
                  max: 3000,
                  min: 1024,
                },

                items: 4,
                partialVisibilityGutter: 40,
              },
              mobile: {
                breakpoint: {
                  max: 464,
                  min: 0,
                },
                items: 2,
                slidesToSlide: 1,
                partialVisibilityGutter: 30,
              },
              tablet: {
                breakpoint: {
                  max: 1024,
                  min: 464,
                },

                items: 2,
                partialVisibilityGutter: 30,
              },
            }}
            rewind={false}
            rewindWithAnimation={false}
            rtl={false}
            shouldResetAutoplay
            showDots={false}
            sliderClass=""
            slidesToSlide={2}
            swipeable
            transitionDuration={1000}
          >
            {topProductData?.length > 0 &&
              topProductData?.map((c) => (
                <div
                  style={{
                    width: "100%",
                    height: "300px",
                    //   backgroundColor: "red",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    columnGap: "10px",
                    //   border: "1px solid gray",
                    marginBottom: "10px",
                    borderRadius: "5px",
                  }}
                  className="main-div"
                >
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      height: "100%",
                    }}
                    className="auto-mid-div"
                  >
                    <div className="image-wrapper">
                      <img
                        src={`${keys.IMAGE_URL_PRODUCTION}/${c?.images[0]}`}
                        alt="product-auto"
                        className="auto-image"
                      />
                    </div>
                    <div
                      style={{
                        width: "100%",
                        height: "40%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                        flexDirection: "column",
                        padding: "5px",
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          // height: "100%",
                          marginTop: "5px",
                          flexDirection: "column",
                          rowGap: "5px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            flexDirection: "column",
                          }}
                        >
                          <span
                            style={{
                              fontWeight: "bold",
                              // wordBreak: "break-all",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              width: "100%",
                              fontSize: "12px",
                            }}
                          >
                            {c?.title.slice(0, 30)}
                          </span>
                          <span style={{ fontWeight: "bold" }}>
                            Price :{" "}
                            <span style={{ color: "green" }}>
                              &#8377;
                              {c?.category === "mobile_phone"
                                ? formatIndianNumber(
                                    c?.allVariant?.[0]?.discount_price
                                  )
                                : formatIndianNumber(c?.discount)}
                            </span>
                          </span>
                        </div>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Link
                          to={{
                            pathname: `/productDetails/${c?._id}`,
                          }}
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <CartButton
                            style={{
                              width: "60%",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              padding: "8px",
                            }}
                          >
                            View
                          </CartButton>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </Carousel>
        </>
      )}
    </div>
  );
};

export default TopProducts;
