import styled from "styled-components";

export const CheckoutMainContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 100px;
  flex-direction: column;

  @media only screen and (min-width: 768px) and (max-width: 1291px) {
    padding: 10px;
  }
  @media only screen and (min-width: 268px) and (max-width: 768px) {
    padding: 10px;
  }
`;
export const CheckoutContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: row;

  @media only screen and (min-width: 268px) and (max-width: 768px) {
    flex-direction: column-reverse;
  }
`;
export const OrderSummaryTitle = styled.span`
  font-size: 20px;
  font-family: "Roboto";
  font-weight: 700;
`;

export const OrderSummaryWrapper = styled.div`
  width: 50%;

  @media only screen and (min-width: 268px) and (max-width: 768px) {
    width: 100%;
  }
`;
export const OrderSummaryOverView = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  overflow: scroll;

  @media only screen and (min-width: 268px) and (max-width: 768px) {
    padding: 10px;
    gap: 2rem;
    height: 400px;
  }
`;

export const OrderDetailsContainer = styled.div`
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const OrderProduct = styled.div`
  width: "100%";
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  /* border:1px solid red; */

  @media only screen and (min-width: 768px) and (max-width: 1121px) {
    grid-template-columns: repeat(1, 1fr);
  }
  @media only screen and (min-width: 468px) and (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
  @media only screen and (max-width: 468px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;
export const OrderImageContainer = styled.div`
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  row-gap: 1rem;
`;
export const ProductImageView = styled.img`
  /* width: 100px; */
  height: 100px;
  object-fit: contain;
`;

export const ProductDescContainer = styled.div`
  height: 100%;
  padding: 10px;
  @media screen and (max-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
export const PriceDetailContainer = styled.div`
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
  gap: 3em;

  @media only screen and (min-width: 320px) and (max-width: 768px) {
    flex-direction: row;
    gap: 0.5em;
  }
`;
export const OrderProductTitle = styled.span`
  width: 45%;
  font-size: 12px;
  font-weight: 400;
  font-family: "Roboto";
  @media screen and (max-width: 768px) {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const PriceView = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;

  @media only screen and (max-width: 468px) {
    justify-content: space-evenly;
  }
`;

export const OrderSubtotal = styled.div`
  width: 100%;
  padding: 10px 40px;
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  font-family: "Roboto";
  font-weight: 700;
  @media only screen and (min-width: 468px) and (max-width: 768px) {
    width: 100%;
  }
  @media only screen and (max-width: 468px) {
    width: 100%;
  }
`;
export const Verifybutton = styled.button`
  width: 40%;
  padding: 10px;
  border: none;
  font-size: 20px;
  font-family: "Roboto";
  font-weight: 600;
  background-color: black;
  color: white;
`;
export const Note = styled.div`
  width: 90%;
  display: flex;
  align-items: start;
  justify-content: start;
  font-size: 16px;
  font-family: "Roboto";
  color: red;
  font-weight: 500;
  margin-bottom: 30px;
`;
export const RegisterMaindiv = styled.div`
  width: 50%;
  @media only screen and (min-width: 268px) and (max-width: 768px) {
    /* border: 1px solid blue; */
    width: 100%;
  }
`;
export const FormDataError = styled.span`
  font-size: 16px;
  font-weight: 500;
  font-family: "Roboto";
  color: white;
`;
export const FormDataErrorDiv = styled.div`
  width: 60%;
  background-color: red;
  color: white;
  display: flex;
  text-align: flex-start;
  justify-content: center;
  padding: 8px;
  position: relative;
  @media only screen and (min-width: 268px) and (max-width: 768px) {
    /* border: 1px solid blue; */
    width: 100%;
  }
`;
export const ModalDiv = styled.div`
  @media only screen and (min-width: 268px) and (max-width: 768px) {
    .ant-modal-body {
      width: 100%;
    }
  }
`;
// export const ProductImageView=styled.div``
// export const ProductImageView=styled.div``
// export const ProductImageView=styled.div``
// export const ProductImageView=styled.div``
// export const ProductImageView=styled.div``
// export const ProductImageView=styled.div``
// export const ProductImageView=styled.div``
// export const ProductImageView=styled.div``
// export const ProductImageView=styled.div``
// export const ProductImageView=styled.div``
// export const ProductImageView=styled.div``
// export const ProductImageView=styled.div``
// export const ProductImageView=styled.div``
// export const ProductImageView=styled.div``
// export const ProductImageView=styled.div``
