import styled from "styled-components";
export const commonColor = "#146cda";

export const TopNavbarDiv = styled.div`
  width: 100%;
  color: #fff;
  height: 80px;
  display: flex;
  align-items: center;
  /* justify-content: space-between; */

  @media only screen and (max-width: 781px) and (min-width: 991px) {
  }
  @media only screen and (max-width: 781px) and (min-width: 481px) {
    justify-content: space-between;
    padding: 10px 20px;
  }
  @media only screen and (max-width: 481px) and (min-width: 320px) {
    width: 100%;
    justify-content: space-between;
    padding: 10px 20px;
    height: 60px;
  }
  @media only screen and (max-width: 320px) {
    width: 100%;
    justify-content: space-between;
    padding: 10px;
    height: 60px;
  }
`;

export const TopNavbarRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35%;
  flex-direction: column;
  height: 80px;

  @media only screen and (max-width: 991px) and (min-width: 781px) {
    width: 50%;
  }

  @media only screen and (max-width: 781px) and (min-width: 481px) {
    justify-content: flex-start;
    align-items: flex-start;
    width: 80%;
  }
  @media only screen and (max-width: 481px) and (min-width: 320px) {
    width: 80%;
  }
  @media only screen and (max-width: 320px) {
    width: 80%;
  }
`;
export const Logo = styled.span`
  font-size: 3em;
  color: #0f0f82;
  /* color: black; */
  font-family: "Roboto";
  font-weight: 800;
  letter-spacing: 1px;
  /* border:1px solid red; */
  display: inline-block;
  margin-bottom: -20px;

  @media only screen and (max-width: 1191px) and (min-width: 781px) {
    font-size: 2em;
    padding-top: 10px;
    padding-left: 10px;
    /* height:30px; */
  }
  @media only screen and (max-width: 781px) and (min-width: 481px) {
    font-size: 2em;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin-left:-10px; */
  }
  @media only screen and (max-width: 481px) {
    font-size: 1.8em;
  }
`;

export const SubTitle = styled.p`
  /* color: #0f0f82; */
  color: black;
  font-family: Roboto;
  font-size: 16px;

  @media only screen and (max-width: 1191px) and (min-width: 781px) {
    font-size: 14px;
    text-align: center;
    text-align: justify;
    padding: 5px 10px;
  }

  @media (max-width: 781px) {
    /* display: none; */
    font-size: 10px;
  }
`;
export const LeftWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  height: 60px;
  width: 80%;

  @media only screen and (max-width: 1191px) and (min-width: 781px) {
    width: 100%;
    margin-right: -35px;
  }
  @media only screen and (max-width: 781px) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #fff;
    transition: 0.7s;
    transform: translateY(-100vh);
    z-index: 1;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
    &.responsive-nav {
      transform: none;
    }
  }
  @media only screen and (max-width: 368px) {
    height: 30%;
  }
`;
export const HamburgerWrapper = styled.div`
  padding: 5px;
  outline: none;
  font-size: 1.8rem;
  background: transparent;
  color: black;
  border: none;
  visibility: hidden;
  opacity: 0;

  &.nav-close-btn {
    position: absolute;
    top: -15px;
    right: 1rem;
  }

  @media only screen and (max-width: 781px) {
    visibility: visible;
    opacity: 1;
    cursor: pointer;
  }
  @media only screen and (max-width: 320px) {
    padding: 0px 3px;
    margin-top: -10px;
    font-size: 1.3rem;
    &.nav-close-btn {
      position: absolute;
      top: 5px;
      right: 1rem;
    }
  }
`;
export const TopNavbarCenter = styled.div`
  width: 55%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid gray;
  /* border-radius: 4px; */
  position: relative;

  @media only screen and (max-width: 1191px) and (min-width: 781px) {
    width: 100%;
  }

  @media (max-width: 781px) {
    width: 100%;
    margin-top: 48px;
  }
`;

export const NavCenterWrapper = styled.div`
  width: 100%;
  display: flex;

  @media (max-width: 781px) {
    width: 20%;
  }
`;
export const CatSelect = styled.select`
  padding: 10px 8px;
  color: black;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  border-right: 1px solid black;
  border-right-width: 2px;
  border-right-color: gray;
  border-left: none;
  border-top: none;
  border-bottom: none;
  outline: none;

  @media only screen and (max-width: 991px) and (min-width: 781px) {
    width: 40%;
  }

  @media only screen and (max-width: 781px) and (min-width: 368px) {
    width: 25%;
  }
  @media only screen and (max-width: 368px) {
    padding: 0px;
    font-size: 12px;
    width: 30%;
  }
`;
export const SearchInput = styled.input`
  padding: 10px;
  width: 100%;
  font-size: 14px;
  color: black;
  border-left: none;
  outline: none;
  border: none;
  letter-spacing: 0.8px;

  @media only screen and (max-width: 991px) and (min-width: 781px) {
    /* width:100%; */
  }
  @media only screen and (max-width: 368px) {
    font-size: 13px;
  }
`;
export const SearchBtn = styled.button`
  padding: 10px;
  width: 14%;
  border: none;
  color: white;
  background-color: #0f0f82;
  border: none;
  font-weight: 500;

  @media only screen and (min-width: 991px) {
    width: 25%;
  }

  @media only screen and (max-width: 991px) and (min-width: 781px) {
    width: 20%;
  }

  @media only screen and (max-width: 781px) {
    width: 25%;
    padding: 10px 0px;
    font-size: 12px;
    height: 100%;
  }
  @media only screen and (max-width: 368px) {
    font-size: 12px;
    height: 100%;
  }
`;
export const TopNavbarLeft = styled.div`
  width: 25%;
  display: flex;
  align-items: center;
  margin-left: 20%;

  @media only screen and (max-width: 1191px) and (min-width: 781px) {
    width: 50%;
  }
  @media (max-width: 781px) {
    width: 100%;
    margin: 2rem auto;
    color: white;
  }
`;

export const TopLeftContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media only screen and (max-width: 1191px) and (min-width: 781px) {
    justify-content: space-between;
  }
`;

export const AccountTitle = styled.a`
  color: black;
  width: 100%;
  font-size: ${(props) => (props.hello ? `17px` : `20px`)};
  font-family: ui-monospace;
  font-weight: 400;
`;
export const TopNavbarLeftFisrt = styled.div`
  width: 35%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Search = styled.input`
  width: 65%;
  :focus-visible {
    outline: none;
  }
  border: 1px solid black;
  height: 46px;
  line-height: 42px;
  padding: 20px 20px 20px 20px;
  color: black;
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;

  @media only screen and (max-width: 781px) and (min-width: 481px) {
    width: 50%;
    height: 26px;
    line-height: 22px;
    padding: 10px;
  }
`;
export const SearchButton = styled.button`
  cursor: pointer;
  background-color: #0f0f82;
  font-size: 35px;
  display: block;
  line-height: 40.9px;
  border: none;
  border-radius: 0px 5px 5px 0px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -2px;

  @media only screen and (max-width: 781px) and (min-width: 481px) {
    font-size: 26px;
    display: block;
    line-height: 20.9px;
    margin-left: 0px;
  }
`;
export const SearchButtonTitle = styled.span`
  color: white;
  padding: 4px 20px;
  font-size: 1rem;
  font-family: "Roboto";
  font-weight: 500;
`;

// top left

export const ItemWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  /* padding-left: 10px; */
  /* z-index: 9999999999999; */

  @media only screen and (max-width: 1191px) and (min-width: 781px) {
  }
  @media only screen and (max-width: 781px) and (min-width: 481px) {
  }
`;
export const ItemTitle = styled.span`
  font-size: 12px;
  color: #213038;
`;
export const CartContentContainer = styled.div`
  width: 350px;
  height: 300px;
  z-index: 99999999 !important;

  & .ant-popover-inner {
    background-color: lightblue !important;
    z-index: 99999999 !important;
  }

  @media only screen and (max-width: 781px) {
    width: 400px;
    height: 300px;
    margin-top: 50px;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
    display: none;
  }
`;
export const CartContentWrapper = styled.div`
  width: 100%;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
`;
export const CartItemContent = styled.div`
  width: 100%;
  height: 250px;
  overflow: scroll;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
`;
export const CartItemInnerContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-bottom: 30px;
`;

export const CartItemImage = styled.img`
  width: 50px;
  height: 50px;
  object-fit: contain;
  /* @media only screen and (max-width: 781px){
    height: 20px;
    width: 20px;
  } */
`;

export const CartItemTitle = styled.span`
  font-size: 12px;
  width: 45%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;
export const CheckOutButton = styled.button`
  border: none;
  padding: 8px 20px;
  font-weight: 500;
  font-family: "Roboto";
  letter-spacing: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.bg};
  color: ${(props) => props.color};
  cursor: pointer;
`;
export const Price = styled.span`
  font-weight: 600;
  font-family: "Roboto";
  display: flex;
  font-size: 15px;

  align-items: center;
  justify-content: center;
  margin-top: 6px;
`;
export const TotalPriceSection = styled.span`
  font-weight: 700;
  font-family: "Roboto";
  font-size: 22px;
`;

export const CartPopOver = styled.div`
  width: 100%;
  @media only screen and (max-width: 1024px) {
    display: none;
  }
`;
export const CartPopOverMobile = styled.div`
  width: 100%;
  @media only screen and (min-width: 1024px) and (max-width: 5000px) {
    display: none;
  }
`;
