import React from "react";
import {
  BannerOneContainer,
  BannerTwoContainer,
  ShopNowBtnWrapper,
  ShopNowBtn,
  BannerImage,
} from "./banner1.style";
import bannerOne from "../../images/banner/b1.jpg";
import bannerTwo from "../../images/banner/poster13.jpg";
import { Link } from "react-router-dom";
export default function TwoBanner({ imageSrc, title, bg, color, of, mb, url }) {
  return (
    <BannerTwoContainer>
      <BannerOneContainer>
        <ShopNowBtnWrapper
          style={{
            bottom: `${mb ? mb : `15%`}`,
          }}
        >
          {/* <span style={{ fontSize: "35px", fontWeight: "700" }}>
            New Crystal 4k
          </span>
          <span
            style={{ lineHeight: "2em", fontSize: "15px", fontWeight: "500" }}
          >
            Starting &#8377; 567800*
          </span> */}
          <Link to={url}>
            <ShopNowBtn
              style={{
                backgroundColor: `${bg}`,
                color: `${color}`,
              }}
            >
              {title}
            </ShopNowBtn>
          </Link>
        </ShopNowBtnWrapper>
        <BannerImage
          src={imageSrc}
          // style={{
          //   objectFit: `${of ? of : null}`,
          // }}
          alt="banner-img"
        />
      </BannerOneContainer>
    </BannerTwoContainer>
  );
}
