import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Cards from "../components/Card/Cards";
import LatestProduct from "../components/LatestProduct";
import TwoSingleBanner from "../components/TwoBanner";
import ProductHeader from "../components/ProductHeader/ProductHeader";
import FiveProduct from "../components/FiveProductShowSection";
import SmallProductCard from "../components/Smallcard";
import TopProducts from "../components/AutoPlayCard/AutoPlay";

export default function Product() {
  // useEffect(() => {
  //   AOS.init();
  //   AOS.refresh();
  // }, []);

  return (
    <div style={{ padding: "15px" }}>
      <>
        <FiveProduct />
        {/* <SmallProductCard /> */}
        <TopProducts />
        <ProductHeader />
        <Cards />
        <LatestProduct />
      </>
    </div>
  );
}
