import styled from "styled-components";

export const TopSubNavDiv = styled.div`
  width: 100%;
  height: 40px;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* position: sticky;
  top: 50%; */

  @media only screen and (max-width: 1291px) and (min-width: 781px) {
  }
  @media only screen and (max-width: 781px) and (min-width: 100px) {
    width: 100%;
  }
`;
export const SubLeftContainer = styled.div`
  width: 80%;
  display: flex;
  /* align-items: start; */
  /* justify-content: flex-start; */
  color: white;

  @media only screen and (max-width: 1491px) and (min-width: 1251px) {
    width: 100%;
  }

  @media only screen and (max-width: 1024px) and (min-width: 782px) {
    position: absolute;
    top: 10%;
    left: 0%;
    flex-direction: column;
    width: 100%;
    height: 100%;
    z-index: 999;
    background-color: #000;
    transition: 0.7s;
    transform: translateX(-100vw);
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
    padding-top: 0.7rem;
    /* border:1px solid blue; */
    &.responsive-nav {
      transform: none;
    }
  }
  @media only screen and (max-width: 781px) and (min-width: 481px) {
    position: absolute;
    top: 10%;
    left: 0%;
    flex-direction: column;
    // width: 100%;
    height: 100%;
    z-index: 999;
    background-color: #000;
    transition: 0.7s;
    transform: translateX(-100vw);
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
    padding-top: 0.7rem;
    /* border:1px solid blue; */
    &.responsive-nav {
      transform: none;
    }
  }
  @media only screen and (max-width: 481px) and (min-width: 381px) {
    position: absolute;
    top: 9%;
    left: 0%;
    flex-direction: column;
    // width: 100%;
    height: 100%;
    z-index: 999;
    background-color: #000;
    transition: 0.7s;
    transform: translateX(-100vw);
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
    padding-top: 0.7rem;
    /* border:1px solid blue; */
    &.responsive-nav {
      transform: none;
    }
  }
  @media only screen and (max-width: 381px) and (min-width: 100px) {
    position: absolute;
    top: 10%;
    left: 0%;
    flex-direction: column;
    width: 100%;
    // height: 100%;
    z-index: 999;
    background-color: #000;
    transition: 0.7s;
    transform: translateX(-100vw);
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
    padding-top: 0.7rem;
    /* border:1px solid blue; */
    &.responsive-nav {
      transform: none;
    }
  }
`;

export const SubDivWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  ${(props) => props.bg && `background-color:white; color:white;`}
  margin-left: 10px;
  height: 40px;
  :hover {
    background-color: white;
  }
  :hover span {
    color: black;
  }

  @media only screen and (max-width: 1291px) and (min-width: 781px) {
    width: 90%;
    /* justify-content: space-between; */
    /* margin-left: 5px; */
  }
  @media (max-width: 768px) {
    /* margin-left: 25%; */
    width: 90%;
  }
`;

export const CategoryItem = styled.span`
  color: ${(props) => (props.color ? `black` : `white`)};
  font-family: "Roboto";
  font-size: 1em;
  cursor: pointer;
  font-weight: 700;
  text-align: center;
  padding: 2px 15px;
  letter-spacing: 1px;
  :hover {
    color: black;
  }
  @media only screen and (max-width: 1291px) and (min-width: 781px) {
    /* padding: 2px 0px; */
    width: 480px;
  }
  @media only screen and (max-width: 991px) and (min-width: 781px) {
    /* width:460px; */
    font-size: 0.8em;
  }
  @media only screen and (max-width: 781px) and (min-width: 481px) {
    padding: 2px 0px;
    width: 460px;
  }
`;

export const SubRightContainer = styled.div`
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 991px) and (min-width: 781px) {
    width: 35%;
  }

  @media only screen and (max-width: 781px) and (min-width: 481px) {
    width: 30%;
  }
  @media only screen and (max-width: 481px) and (min-width: 320px) {
    width: 40%;
  }
`;
export const PhoneLink = styled.a``;
export const Phone = styled.span`
  color: white;
  font-size: 20px;

  @media only screen and (max-width: 1291px) and (min-width: 991px) {
    font-size: 18px;
  }

  @media only screen and (max-width: 991px) and (min-width: 781px) {
    font-size: 16px;
  }
  @media only screen and (max-width: 781px) and (min-width: 321px) {
    font-size: 14px;
  }
  @media only screen and (max-width: 481px) and (min-width: 320px) {
    font-size: 12px;
  }
`;

export const HamburgerWrapper = styled.div`
  padding: 5px;
  outline: none;
  font-size: 1.5rem;
  background: transparent;
  color: white;
  border: none;
  visibility: hidden;
  opacity: 0;

  @media only screen and (max-width: 1024px) {
    visibility: visible;
    opacity: 1;
    padding-top: 0;
    margin-top: -5px;
    cursor: pointer;

    &.nav-close-btn {
      position: absolute;
      top: 10px;
      right: 0.5rem;
    }
  }
  @media only screen and (max-width: 320px) {
    /* padding: 0px 3px;
    margin-top:-10px;
    font-size:1.3rem;

    &.nav-close-btn{
        position:absolute;
        top: 5px;
        right: 1rem;
    } */
  }
`;

// export const SubDivWrapper = styled.div``;
// export const SubDivWrapper = styled.div``;
// export const SubDivWrapper = styled.div``;
// export const SubDivWrapper = styled.div``;
// export const SubDivWrapper = styled.div``;
// export const SubDivWrapper = styled.div``;
// export const SubDivWrapper = styled.div``;
// export const SubDivWrapper = styled.div``;
// export const SubDivWrapper = styled.div``;
// export const SubDivWrapper = styled.div``;
// export const SubDivWrapper = styled.div``;
// export const SubDivWrapper = styled.div``;
// export const SubDivWrapper = styled.div``;
// export const SubDivWrapper = styled.div``;
// export const SubDivWrapper = styled.div``;
// export const SubDivWrapper = styled.div``;
// export const SubDivWrapper = styled.div``;
// export const SubDivWrapper = styled.div``;
// export const SubDivWrapper = styled.div``;
