import React from "react";
import TopNavbar from "../components/TopNavigation";
import TopSubNav from "../components/TopSubNav";

export default function Navbar(props) {
  return (
    <>
      <div
        style={{
          position: "sticky",
          top: "0%",
          zIndex: "99999",
          backgroundColor: "white",
          borderBottom: "1px solid black",
        }}
      >
        <TopNavbar />
      </div>
      <TopSubNav />
    </>
  );
}
