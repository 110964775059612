import styled from "styled-components";

export const TitleHeader = styled.div`
  display:flex;
  align-items:center;
  justify-content:space-between;
  padding:0px 25px;

  @media only screen and (max-width:768px) and (min-width:468px){ 
  }
  @media only screen and (max-width:468px) and (min-width:368px){
  }
  @media only screen and (max-width:368px){
    padding:0px 10px;
  }
`
export const HeaderTitle = styled.h1`
 font-family:"Roboto Mono";
 width:100%;
 color:gray;
 margin-top:10px;

 @media only screen and (max-width:768px) and (min-width:468px){
   font-size:28px;
 }
 @media only screen and (max-width:468px) and (min-width:368px){
   font-size:24px;
 }
 @media only screen and (max-width:368px){
   font-size:22px;
 }
`
export const MoreIconWrapper = styled.div`
    display:flex;
    align-items:center;
    cursor: pointer;
    border:1px solid #e0e2e9;
    padding:5px;
    border-radius: 10px;

    @media only screen and (max-width:768px) and (min-width:468px){
      
    }
    @media only screen and (max-width:468px) and (min-width:368px){
      
    }
    @media only screen and (max-width:368px){
      padding:3px 5px;
    }
`
export const MoreTitle = styled.span`
    font-size:1rem;
    font-family:"Roboto Mono";
    display:flex;
    align-items:center;

    @media only screen and (max-width:768px) and (min-width:468px){
   
    }
    @media only screen and (max-width:468px) and (min-width:368px){
      font-size:0.8rem;
    }
    @media only screen and (max-width:368px){
      font-size:0.8rem;
    }
`
