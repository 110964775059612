import React, { useState } from "react";

import {
  AddToCartButton,
  CartButton,
  PriceSection,
  ProductDescriptionLeft,
  ProductDescriptionRight,
  ProductDetailCardContainer,
  ProductDetailLeft,
  ProductDetailRight,
  ProductImageSection,
  ProductTitle,
} from "./style";
import { Link } from "react-router-dom";
import { useStateContext } from "../../contextApi/StateContext";
import { AiOutlineHeart } from "react-icons/ai";
import * as keys from "../../config/keys";
import { RiHeart3Fill } from "react-icons/ri";
export default function ProductDetailCard({ item, w, activeSideFilter }) {
  const { addToWishlist, wishlistData } = useStateContext();

  const [hover, setHover] = useState(false);

  function formatIndianNumber(number) {
    if (typeof number === "number") {
      // Use toLocaleString() with 'en-IN' locale for Indian number formatting
      return number.toLocaleString("en-IN");
    } else {
      const convertNumberToInt = parseInt(number);
      return convertNumberToInt.toLocaleString("en-IN");
    }
  }

  const handleMouseEnter = () => {
    setHover(true);
  };

  const handleMouseLeave = () => {
    setHover(false);
  };
  return (
    <>
      <ProductDetailCardContainer w={w} activeSideFilter={activeSideFilter}>
        {item.category !== "mobile_phone" && (
          <div
            style={{
              position: "absolute",
              top: "5px",
              right: "15px",
              padding: "5px",
              fontSize: "20px",
              fontWeight: "600",
              cursor: "pointer",
            }}
            onClick={() => addToWishlist(item)}
          >
            {wishlistData &&
            wishlistData?.find((wishlistID) => wishlistID._id === item._id) ? (
              <RiHeart3Fill color="#f44343" style={{ fontSize: "23px" }} />
            ) : (
              <AiOutlineHeart
                color="#f44343"
                colorProfile={"#f44343"}
                style={{ fontSize: "23px" }}
              />
            )}
          </div>
        )}
        <Link
          to={{
            pathname: `/productDetails/${item._id}`,
          }}
        >
          {item?.allVariant[0]?.discount_percentage > 0 && (
            <div
              style={{
                position: "absolute",
                top: "10px",
                left: "15px",
                backgroundColor: "#1800be",
                color: "white",
                padding: "5px",
                fontWeight: "600",
              }}
            >
              {item?.allVariant[0]?.discount_percentage}% off
            </div>
          )}
          <ProductDetailLeft
            onMouseEnter={() => handleMouseEnter()}
            onMouseLeave={() => handleMouseLeave()}
          >
            {!hover ? (
              <ProductImageSection
                src={`${keys.IMAGE_URL_PRODUCTION}/${item.images[0]}`}
                alt="image"
              />
            ) : (
              <ProductImageSection
                src={`${keys.IMAGE_URL_PRODUCTION}/${item.images[1]}`}
                alt="image"
              />
            )}
          </ProductDetailLeft>
          <ProductDetailRight>
            <ProductDescriptionLeft>
              <ProductTitle>
                {/* (${item.rom}GB Storage and ${item.ram}GB RAM) */}
                {item.category === "mobile_phone"
                  ? `${item.title.slice(0, 30)}...`
                  : `${item.title.slice(0, 30)}...`}
              </ProductTitle>
            </ProductDescriptionLeft>
            <ProductDescriptionRight>
              <PriceSection>
                &#8377;{" "}
                {item?.category === "mobile_phone"
                  ? formatIndianNumber(item?.allVariant[0]?.discount_price)
                  : formatIndianNumber(item.discount)}
                {/* {formatIndianNumber(item.discount) ||
                  formatIndianNumber(item.price)} */}
                <span
                  style={{
                    textDecoration: "line-through",
                    fontSize: "13px",
                    marginLeft: "4px",
                    color: "grey",
                  }}
                >
                  &#8377;
                  {item?.category === "mobile_phone"
                    ? formatIndianNumber(item?.allVariant[0]?.price)
                    : formatIndianNumber(item?.price)}
                </span>
              </PriceSection>
              <AddToCartButton>
                <CartButton>View Product</CartButton>
              </AddToCartButton>
            </ProductDescriptionRight>
          </ProductDetailRight>
        </Link>
      </ProductDetailCardContainer>
    </>
  );
}
