import { MenuOutlined, CloseOutlined } from "@ant-design/icons";
import { Badge, Popover } from "antd";

import React, { useState, useRef } from "react";
import ModalAccount from "../Modal";
import { BsBag } from "react-icons/bs";
import { AiOutlineHeart, AiOutlineArrowRight } from "react-icons/ai";
import { BiUser } from "react-icons/bi";
import { TiDeleteOutline } from "react-icons/ti";
import { IoLocationOutline } from "react-icons/io5";
import {
  ButtonContainer,
  CartContentContainer,
  CartContentWrapper,
  CartItemContent,
  CartItemImage,
  CartItemInnerContent,
  CartItemTitle,
  CheckOutButton,
  ItemTitle,
  ItemWrapper,
  Logo,
  Price,
  TopLeftContainer,
  TopNavbarCenter,
  TopNavbarDiv,
  TopNavbarLeft,
  TopNavbarRight,
  TotalPriceSection,
  LeftWrapper,
  SubTitle,
  HamburgerWrapper,
  SearchInput,
  SearchBtn,
  CartPopOver,
  CartPopOverMobile,
} from "./styled";
import { useStateContext } from "../../contextApi/StateContext";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import Modal2 from "../Modal/modal2";
import * as keys from "../../config/keys";

import CartImage from "../../images/cart1.png";
import { FilterDataAdvanced } from "filter-data-advanced/dist/FilterDataAdvanced";

export default function TopNavbar(props) {
  const [modal1Visible, setModal1Visible] = useState(false);
  const [modal2Visible, setModal2Visible] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [user, setUser] = useState(false);
  const [productItems, setItems] = useState([]);
  const [searchValue, setValue] = useState("");
  const [totalPriceValue, setTotalPriceValue] = useState();
  const [productSearch, setProductSearch] = useState();
  const { allProduct } = useStateContext();
  const navRef = useRef();
  const [searchResultData, setSearchData] = useState();

  const { cartItems, removeItem, wishlistData } = useStateContext();
  const handleSearch = (e) => {
    const data = e.toLocaleLowerCase();
    const regex = new RegExp(data, "gi");

    const productfind = allProduct?.filter((x) => {
      return x.title.toLocaleLowerCase().includes(data);
    });
    setProductSearch(productfind);
  };
  const handleOnChangeSearch = (e) => {
    let obj = new FilterDataAdvanced();
    let searchdata = obj.filterByKeyValue(allProduct, "title", e);
    setSearchData(searchdata);
  };

  useEffect(() => {
    setItems(cartItems);
    setTotalPriceValue(
      cartItems?.reduce((acc, obj) => acc + obj.productPrice * obj.quantity, 0)
    );
  }, [cartItems]);
  const content = (
    <CartContentContainer>
      <h2
        style={{
          textAlign: "center",
          fontSize: "25px",
          fontFamily: "Roboto",
          color: "grey",
          zIndex: "",
        }}
      >
        Your Cart ({productItems.length})
      </h2>
      <CartContentWrapper>
        <CartItemContent>
          {productItems?.length >= 1 ? (
            productItems.map((item) => (
              <CartItemInnerContent>
                <CartItemImage
                  src={`${keys.IMAGE_URL_PRODUCTION}/${item?.images?.[0]}`}
                />
                {/* {item.category} */}
                <CartItemTitle>
                  {item.category && item.category === "mobile_phone"
                    ? `${item.title} ${
                        item.selectedColor === "#B784A7"
                          ? `Opera Muave`
                          : item.selectedColor
                      } (${item.selectedvariant?.rom}GB Storage and ${
                        item.selectedvariant?.ram
                      }GB RAM)`
                    : item?.title}
                </CartItemTitle>
                <span>x {item.quantity}</span>
                <Price>&#8377; {item.quantity * item?.productPrice}</Price>
                {item.category === "mobile_phone" ? (
                  <TiDeleteOutline
                    color="tomato"
                    size={24}
                    onClick={() =>
                      removeItem(
                        item._id,
                        item.discount,
                        item.category,
                        item.uniqueId
                      )
                    }
                  />
                ) : (
                  <TiDeleteOutline
                    color="tomato"
                    size={24}
                    onClick={() =>
                      removeItem(
                        item._id,
                        item.discount,
                        item.category,
                        item.uniqueId
                      )
                    }
                  />
                )}
              </CartItemInnerContent>
            ))
          ) : (
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                style={{ width: "100%", height: "180px" }}
                src={CartImage}
                alt="cartImg"
              />
            </div>
          )}
        </CartItemContent>

        <ButtonContainer>
          {cartItems.length > 0 ? (
            <>
              <Link to={"/product/checkout"}>
                <CheckOutButton bg="#0f0f82" color="white">
                  Checkout
                </CheckOutButton>
              </Link>
              <Link to={"/cart"}>
                <CheckOutButton bg="lightseagreen" color="white">
                  Go to cart
                </CheckOutButton>
              </Link>
              <TotalPriceSection>
                &#8377; {Math.abs(totalPriceValue)}
              </TotalPriceSection>
            </>
          ) : (
            <Link to={"/"}>
              <CheckOutButton bg="black" color="white">
                Continue Shopping{" "}
                <AiOutlineArrowRight style={{ marginLeft: "5px" }} size={22} />
              </CheckOutButton>
            </Link>
          )}
        </ButtonContainer>
      </CartContentWrapper>
    </CartContentContainer>
  );
  const showNavbar = () => {
    navRef.current.classList.toggle("responsive-nav");
  };

  return (
    <TopNavbarDiv>
      <TopNavbarRight>
        <Link to={"/"}>
          <Logo>CLASSIC</Logo>
          <SubTitle>A House of consumer electronics & appliances</SubTitle>
        </Link>
      </TopNavbarRight>
      <LeftWrapper ref={navRef}>
        <TopNavbarCenter>
          <SearchInput
            type="text"
            className="search-input"
            placeholder="Search products......"
            value={searchValue}
            onChange={(e) => {
              setValue(e.target.value);
              handleOnChangeSearch(e.target.value);
            }}
          />

          {searchValue.length > 0 && (
            <SearchBtn onClick={(e) => setValue("")}>Close</SearchBtn>
            // <SearchBtn onClick={(e) => handleSearch(e)}>Clear Search</SearchBtn>
          )}
          {searchValue.length > 0 && searchResultData.length > 0 && (
            <div
              style={{
                width: "100%",
                background: "whitesmoke",
                height: "auto",
                position: "absolute",
                top: "50px",
                padding: "20px",
                zIndex: "99999",
                color: "black",
                // display: "flex",
                // alignItems: "flex-start",
                // justifyContent: "center",
                // flexDirection: "column",
                // overflow: "scroll",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "40vh",
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  flexDirection: "column",
                  overflow: "scroll",
                  overflowX: "hidden",
                }}
              >
                {searchResultData?.map((p) => (
                  <>
                    <Link
                      to={`/productDetails/${p._id}`}
                      style={{ marginTop: "15px" }}
                      onClick={() => {
                        setValue("");
                        showNavbar();
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-around",
                        }}
                      >
                        <div style={{ marginRight: "10px" }}>
                          <img
                            src={`${keys.IMAGE_URL_PRODUCTION}/${p?.images?.[0]}`}
                            alt="product image"
                            style={{
                              width: "50px",
                              height: "50px",
                              objectFit: "contain",
                            }}
                          />
                        </div>
                        <span>{p.title}</span>
                      </div>
                    </Link>
                  </>
                ))}
              </div>
            </div>
          )}
        </TopNavbarCenter>
        <TopNavbarLeft>
          <TopLeftContainer>
            <Link to={"/store"} style={{ width: "100%" }}>
              <ItemWrapper>
                <IoLocationOutline color="black" size={24} />
                <ItemTitle>Store</ItemTitle>
              </ItemWrapper>
            </Link>
            <CartPopOver>
              <Popover
                content={content}
                placement="bottom"
                style={{
                  zIndex: "-1",
                }}
              >
                <ItemWrapper>
                  <Badge
                    count={cartItems.length}
                    offset="6"
                    title="cart"
                    showZero
                  >
                    <BsBag color="black" size={24} />
                  </Badge>
                  <ItemTitle>Cart</ItemTitle>
                </ItemWrapper>
              </Popover>
            </CartPopOver>
            <CartPopOverMobile>
              <Link to={"/cart"}>
                <ItemWrapper>
                  <Badge
                    count={cartItems.length}
                    offset="6"
                    title="cart"
                    showZero
                  >
                    <BsBag color="black" size={24} />
                  </Badge>
                  <ItemTitle>Cart</ItemTitle>
                </ItemWrapper>
              </Link>
            </CartPopOverMobile>
            <Link to={"/wishlist"} style={{ width: "100%" }}>
              <ItemWrapper>
                <Badge
                  count={wishlistData.length}
                  offset="6"
                  title="wishlist"
                  showZero
                  style={{
                    cursor: "pointer",

                    fontWeight: "500",
                  }}
                >
                  <AiOutlineHeart color="black" size={24} />
                </Badge>
                <ItemTitle>Wishlist</ItemTitle>
              </ItemWrapper>
            </Link>
            <Link to={"/user/account"} style={{ width: "100%" }}>
              <ItemWrapper>
                <BiUser color="black" size={24} />
                <ItemTitle>Account</ItemTitle>
              </ItemWrapper>
            </Link>
          </TopLeftContainer>
        </TopNavbarLeft>
        <ModalAccount
          setModal1Visible={setModal1Visible}
          setModal2Visible={setModal2Visible}
          modal2Visible={modal2Visible}
          setUser={setUser}
        />
        <Modal2 setIsOpen={setIsOpen} isOpen={isOpen} />
        <HamburgerWrapper className="nav-btn nav-close-btn">
          <CloseOutlined onClick={showNavbar} />
        </HamburgerWrapper>
      </LeftWrapper>
      <HamburgerWrapper className="nav-btn">
        <MenuOutlined onClick={showNavbar} />
      </HamburgerWrapper>
    </TopNavbarDiv>
  );
}
