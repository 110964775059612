import React, { useEffect } from "react";
import {
  LatestProductCard,
  LatestProductCardSection,
  LatestProductContainer,
  LatestProductImage,
  LatestProductTitle,
  LatestProudctWrapper,
  More,
  ProductTitle,
  ViewProductDiv,
  ViewTitle,
  PriceWrapper,
  OffContainer,
  ProductImageWrapper,
  DiscoverTitleContainer,
} from "./style";

import { useState } from "react";
import TwoBanner from "../Banner/TwoBanner";
import axios from "axios";
import * as keys from "../../config/keys";
import { Link } from "react-router-dom";
import { ArrowRightOutlined } from "@ant-design/icons";
import sonyTvImage from "../../images/banner/sonnyBanner/tvSony.webp";
import sonyTvImagePostertwo from "../../images/banner/tv_banner_20-06-23.avif";
import lgWashingMachine from "../../images/banner/poster13.jpg";
import lgWashingMachineTwo from "../../images/banner/lg wasging machine.png";

import samsungBanner from "../../images/banner/samsung/1.png";
import samsungBannerTwo from "../../images/banner/godrej refigirator.png";
import formatIndianNumber from "../common/CurrencyInr";
export default function LatestProduct() {
  const [data, setData] = useState([]);
  const [samsung, setSumsungData] = useState([]);
  const [sony, setSonyData] = useState([]);

  useEffect(() => {
    const getProductByBrand = async () => {
      const lg = await axios.get(`${keys.PRODUCTION_URL}/product/brand/lg`);
      const sony = await axios.get(`${keys.PRODUCTION_URL}/product/brand/sony`);
      const samsung = await axios.get(
        `${keys.PRODUCTION_URL}/product/brand/samsung`
      );
      setData(lg.data);
      setSumsungData(samsung.data);
      setSonyData(sony.data);
    };
    getProductByBrand();
  }, []);

  return (
    <LatestProudctWrapper>
      <LatestProductContainer style={{ marginTop: "40px" }}>
        <DiscoverTitleContainer>
          <LatestProductTitle>
            Discover the latest LG products
          </LatestProductTitle>
          <Link to={"/product/lg"}>
            <More dh={data?.length > 5 ? true : false}>
              More
              <ArrowRightOutlined
                style={{
                  color: "#146cda",
                  cursor: "pointer",
                }}
              />
            </More>
          </Link>
        </DiscoverTitleContainer>
        <LatestProductCardSection>
          {data?.slice(0, 6).map((item) => (
            <ProductCard
              title={item.title}
              images={item.images}
              color={item.color}
              productId={item.id}
              quantity={item.quantity}
              price={item.price}
              discount={item.discount}
              id={item._id}
              discount_percentage={item.discount_percentage}
              ram={item.ram ? item.ram : null}
              rom={item.rom ? item.rom : null}
              category={item.category}
            />
          ))}
        </LatestProductCardSection>
      </LatestProductContainer>
      {/* <TwoBanner
        imageSrc={lgWashingMachineTwo}
        title={"Shop Now"}
        bg={"black"}
        color={"white"}
        url="/product/washing_machine"
        mb={"2%"}
      /> */}
      <LatestProductContainer style={{ marginTop: "40px" }}>
        {samsung?.length >= 1 && (
          <DiscoverTitleContainer>
            <LatestProductTitle>
              Discover the latest Samsung products
            </LatestProductTitle>
            <Link to={"/product/samsung"}>
              <More dh={samsung?.length > 5 ? true : false}>
                More
                <ArrowRightOutlined
                  style={{
                    color: "#146cda",
                    cursor: "pointer",
                  }}
                />
              </More>
            </Link>
          </DiscoverTitleContainer>
        )}
        <LatestProductCardSection>
          {samsung?.slice(0, 6).map((item) => (
            <ProductCard
              title={item.title}
              images={item.images}
              color={item.color}
              productId={item.id}
              quantity={item.quantity}
              price={item.price}
              id={item._id}
              discount={item.discount}
              discount_percentage={item.discount_percentage}
              ram={item.ram ? item.ram : null}
              rom={item.rom ? item.rom : null}
              category={item.category}
            />
          ))}
        </LatestProductCardSection>
      </LatestProductContainer>
      <TwoBanner
        imageSrc={samsungBannerTwo}
        title={"Shop Now"}
        bg={"black"}
        color={"white"}
        of="contain"
        mb="5%"
        url="/product/refrigerator"
      />

      <LatestProductContainer style={{ marginTop: "40px" }}>
        {sony?.length >= 1 && (
          <DiscoverTitleContainer>
            <LatestProductTitle>
              Discover the latest Sony products
            </LatestProductTitle>
            <Link to={"/product/tv"}>
              <More dh={sony?.length > 5 ? true : false}>
                More{" "}
                <ArrowRightOutlined
                  style={{
                    color: "#146cda",
                    cursor: "pointer",
                  }}
                />
              </More>
            </Link>
          </DiscoverTitleContainer>
        )}
        <LatestProductCardSection>
          {sony?.slice(0, 6).map((item) => (
            <ProductCard
              title={item.title}
              images={item.images}
              color={item.color}
              productId={item.id}
              quantity={item.quantity}
              price={item.price}
              id={item._id}
              discount={item.discount}
              discount_percentage={item.discount_percentage}
              ram={item.ram ? item.ram : null}
              rom={item.rom ? item.rom : null}
              category={item.category}
            />
          ))}
        </LatestProductCardSection>
      </LatestProductContainer>
      <TwoBanner
        imageSrc={sonyTvImagePostertwo}
        title={"Shop Now"}
        bg={"black"}
        color={"white"}
        url="/product/tv"
      />
    </LatestProudctWrapper>
  );
}

export const ProductCard = (props) => {
  return (
    <LatestProductCard
      transformNone={props.transformNone}
      relatedProduct={props.relatedProduct}
      color={props.color}
    >
      <Link to={`/productDetails/${props.id}`} style={{ padding: "10px" }}>
        <div style={{ width: "100%" }}>
          <OffContainer data-aos="fade-zoom-in">
            {props.discount_percentage}% off
          </OffContainer>
          <ProductImageWrapper data-aos="zoom-in">
            <LatestProductImage
              src={`${keys.IMAGE_URL_PRODUCTION}/${props.images[0]}`}
              alt="latest product"
            />
          </ProductImageWrapper>
        </div>
        <ProductTitle
          data-aos="fade-up"
          data-aos-anchor-placement="center-bottom"
        >
          {props.title.slice(0, 40)}...
        </ProductTitle>
        <PriceWrapper>
          <span
            data-aos="fade-up"
            data-aos-anchor-placement="center-bottom"
            style={{ fontSize: "19px", color: "red", fontWeight: "bold" }}
          >
            &#8377;{formatIndianNumber(props.discount)}{" "}
          </span>
          <span
            data-aos="fade-up"
            data-aos-anchor-placement="center-bottom"
            style={{
              fontSize: "12px",
              textDecoration: "line-through",
              marginLeft: "4px",
              color: "grey",
            }}
          >
            {/* &#8377;  */}
            {formatIndianNumber(props.price)}
          </span>
        </PriceWrapper>
        <ViewProductDiv>
          <ViewTitle
            data-aos="fade-up"
            data-aos-anchor-placement="center-bottom"
          >
            View Product
          </ViewTitle>
        </ViewProductDiv>
      </Link>
    </LatestProductCard>
  );
};
