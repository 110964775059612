import styled from "styled-components";

export const TwoSingleBannerContainer = styled.div`
  width: 100%;
  padding: 10px 40px;
`;

export const TwoSingleBannerWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 25px;

  @media only screen and (max-width:650px){
    flex-direction: column;
    gap:1rem;
    width:100%;
    border:1px solid red;
  }

  @media only screen and (max-width:481px){
    flex-direction: column;
    width:400px;
  }
 
`;
export const TwoSingleBannerOne = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;
export const TwoSingleBannerTwo = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;
export const BannerImage = styled.img`
  width: 100%;
  /* height: 500px; */
  /* object-fit: cover; */
  @media only screen and (max-width:651px){
    width:1400px;
    height:150px;
  }

  @media only screen and (max-width:481px){
    flex-direction: column;
    width:400px;
  }
`;
export const ImageTitleDiv = styled.div`
  position: absolute;
  bottom: 10px;
  left: 10px;

  @media only screen and (max-width:781px){

  }
  @media only screen and (max-width:481px){
    left:-2.2rem;
    bottom: 5px;
  }
`;
export const ShopNowButton = styled.button`
  width: 100px;
  padding: 6px 8px;
  background-color: red;
  color: white;
  font-weight: 600;
  font-size: 14px;
  border: none;
  border-radius: 5px;
  text-transform: uppercase;

  @media only screen and (max-width:781px){
    font-size: 12px;
    padding: 5px;
    width:80px;
  }
  @media only screen and (max-width:481px){
    /* width:80px; */
    font-size: 10px;
  }
  
`;
// export const TwoSingleBannerContainer = styled.div``;
// export const TwoSingleBannerContainer = styled.div``;
// export const TwoSingleBannerContainer = styled.div``;
// export const TwoSingleBannerContainer = styled.div``;
// export const TwoSingleBannerContainer = styled.div``;
// export const TwoSingleBannerContainer = styled.div``;
// export const TwoSingleBannerContainer = styled.div``;
// export const TwoSingleBannerContainer = styled.div``;
// export const TwoSingleBannerContainer = styled.div``;
// export const TwoSingleBannerContainer = styled.div``;
// export const TwoSingleBannerContainer = styled.div``;
// export const TwoSingleBannerContainer = styled.div``;
// export const TwoSingleBannerContainer = styled.div``;
// export const TwoSingleBannerContainer = styled.div``;
// export const TwoSingleBannerContainer = styled.div``;
// export const TwoSingleBannerContainer = styled.div``;
// export const TwoSingleBannerContainer = styled.div``;
// export const TwoSingleBannerContainer = styled.div``;
// export const TwoSingleBannerContainer = styled.div``;
// export const TwoSingleBannerContainer = styled.div``;
// export const TwoSingleBannerContainer = styled.div``;
